export const navigationTypes = {
    HEADER: 'header',
    FOOTER: 'footer',
};

export const navigationLinks = (type = navigationTypes.HEADER) => {
    return [
        {
            text: 'Blog',
            slug: 'https://reusify.io/blog'
        },
        {
            text: 'Buy cup',
            slug: 'https://reusify.io/shop'
        },
        {
            text: 'About',
            slug: 'https://reusify.io/about'
        },
    ]
};
