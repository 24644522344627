import React, {useEffect, useMemo, useReducer, useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {CloseOutlined, CheckCircleOutlineOutlined, ErrorOutlineOutlined, ExpandMoreOutlined} from '@material-ui/icons';
import {useQuery} from 'react-query';
import { PopupButton } from '@typeform/embed-react';

import {checkIsAuth, fetchAllCupLocation, fetchAllRewards, listAllCups} from '../../api';
import {calcFishToDisplay, iconPathToDisplay} from "../../util/fish_scale";
import LoginStore from "../../store/LoginStore";
import Layout from "../layout";
import {createNewCup} from "../../actions/select_flow_actions";
import Loading from '../loading';
import ScanButton from '../scan_button_float';

// import {StampCardsListItem} from "../stamp_cards/stamp-cards-list-item";
import Gameboard from "../gameboard/gameboard";
import {
  FixCoffeePathGameContext,
  initialState,
  reducer,
} from "../../actions/gameboard-context";
import LeaderboardCups from "../leaderboardCups";

export const alertStatusEnum = {
    error: "Oh no! Something went wrong. Please try again.",
    success: "" ,
}

export const iconMappingEnum = {
    success: <CheckCircleOutlineOutlined/>,
    error: <ErrorOutlineOutlined/>
}

function getC02Value(value, decimals){
  return ((value  * decimals) / 1000) || 0
}

const UserDashboard = () => {
  const {id, rid} = useParams();
  let history = useHistory();
  const loggedIn = LoginStore.isLoggedIn();
  const user = LoginStore.getUser();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [isLoading, setIsLoading] = useState(false);
  const [alertStatus, setAlertStatus] = useState();
  const [openAlert, setOpenAlert] = React.useState(true);
  const [isLogin, setLogin] = useState(loggedIn);
  const [rewardsList, setRewardsList] = useState([]);

  useEffect(() => {
    (async () => {
      const isLoggedIn = await checkIsAuth();
      setLogin(isLoggedIn);
      if(!isLoggedIn) {
        LoginStore.logout();
        history.push({pathname: `/login`});
      }
      if (rid) {
        const rewardsRequest = await fetchAllRewards({
          user_id: id,
          cafe_id: rid,
        })
        setRewardsList(rewardsRequest)
      }
    })()
  }, [])


  const {data: dataCups = [], isLoading: isLoadingFetchAllCups} = useQuery(
    ['cup_location_by_user_id', {
      user_id: id,
    }],
    () => listAllCups({
      user_id: id,
      status: 'MY_CUP',
      count: 'updated_at',
      _order: 'DESC',
      _end: 1
    }),
    {
      keepPreviousData: true,
    }
  );

  const {data: userCupLocationCount = {count: 0}, isLoading: isLoadingUserCupLocationCount} = useQuery(
    ['cup_location_count_by_user_id', {
      user_id: user._key,
    }],
    () => fetchAllCupLocation({
      user_id: user._key,
      count: true,
    }),
    {
      keepPreviousData: true,
    }
  );

  const {data: allCupLocationCount = {count: 0}, isLoading: isLoadingAllCupLocationCount} = useQuery(
    ['all_cup_location_count'],
    () => fetchAllCupLocation({
      count: true,
    }),
    {
      keepPreviousData: true,
    })

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      zIndex: 10,
      position: "fixed",
      top: "65px",
      backgroundColor: alertStatus === "success" ? '#f8f5f7' : '#eb472c',
      color: '#fff',
    },
  }));
  const classes = useStyles();

  const totalSaved = useMemo(() => {
    // weight of the paper cup (12g) + plastic lid (3g) + plastic cup lining (<1g) + cardboard sleeve (3g) = 18g
    return getC02Value(allCupLocationCount && allCupLocationCount.count, 110);
  }, [allCupLocationCount]);

  const userSaved = useMemo(() => {
    return getC02Value(userCupLocationCount && userCupLocationCount.count, 110);
  }, [userCupLocationCount]);

  const fishToDisplay = calcFishToDisplay(userSaved);
  let fishToDisplayString;
  if(fishToDisplay) {
    fishToDisplayString = `${fishToDisplay.fishCount}\u00A0${fishToDisplay.fishName}${fishToDisplay.fishCount > 1 ? 's' : ''}`
  }

  if(!isLogin) {
    console.log("NOT LOGGED IN!")
    LoginStore.logout()
    history.push({pathname: `/login`});
  } else {
    console.log("LOGGED IN!")
  }

  return (
      <FixCoffeePathGameContext.Provider value={{ state, dispatch }}>
        <Layout>
          <Loading isLoading={
            isLoading ||
            isLoadingAllCupLocationCount ||
            isLoadingUserCupLocationCount ||
            isLoadingFetchAllCups
          } />
          {
            alertStatus ?
              <div className={classes.root}>
                {
                  <Collapse in={openAlert}>
                    <Alert severity={alertStatus}
                           action={
                             <IconButton
                               aria-label="close"
                               size="small"
                               onClick={() => {
                                 setOpenAlert(false);
                               }}
                             >
                               <CloseOutlined fontSize="inherit" />
                             </IconButton>
                           }
                    >{alertStatusEnum[alertStatus]}</Alert>
                    <Gameboard />
                    {/*{rewardsList.length > 0 && rewardsList.map((currentStampCard, i) => (*/}
                    {/*    currentStampCard.cafe_id !== "demo" &&*/}
                    {/*    <StampCardsListItem cupLocation={currentStampCard} key={`digital-card-${currentStampCard.key}-${i}`} />*/}
                    {/*))}*/}
                  </Collapse>
                }
              </div> : null
          }
          <div className="user-dashboard">
            <div className="user-dashboard--container">
              <h2 className="type--header-h1 margin-bottom-s margin">Impact Report</h2>
              <p className='type--text margin'>Making a difference together</p>
              <div className="user-dashboard--content-container user-dashboard--content-container-left">
                <span className="user-dashboard--image-container-cup"/>
                <div>
                  <span className="type--text">
                    {dataCups.length > 0 && 'Welcome to the Reusify community!'}
                    <br/>
                      <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                          <p className="type--text strong"> See our heroes </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <LeaderboardCups />
                        </AccordionDetails>
                      </Accordion>
                  </span>
                </div>
              </div>
              <div>
              </div>
              <div className="user-dashboard--content-container user-dashboard--content-container-right">
                <div>
                  <span className="type--text">This year we saved {allCupLocationCount.count || (allCupLocationCount && allCupLocationCount.length)} cups </span>
                  {userCupLocationCount.count >= 1 &&
                    <p className="type--text strong">This year you saved {(userCupLocationCount && userCupLocationCount.count) ? (userCupLocationCount && userCupLocationCount.count) : userCupLocationCount.count} cups </p>}
                </div>
                <span className="user-dashboard--image-container-disposable-cup"/>
              </div>
              <div className="background-top">
                <div className="flex justify-content-between align-items-end">
                  <div style={{textAlign: "left"}}>
                    <p className="type--text">This is how we prevented <br/> {Math.ceil(totalSaved)} kg of C02 emissions</p>
                  </div>
                  <h4 className="type--header-h1">CO2</h4>
                </div>
                <div className="flex justify-content-between align-items-end">
                  <div style={{textAlign: "left"}}>
                    <p className="type--text strong">This is how you prevented <br/> {userSaved < 1 ? `${userSaved * 1000}g` : Math.ceil(userSaved)}kg of C02 emissions</p>
                  </div>
               </div>
              </div>
              {userCupLocationCount.count >= 1 &&
                <div className="flex justify-content-between align-items-center user-dashboard--content">
                  <img src={`${iconPathToDisplay(userSaved)}`} alt=""
                       className="user-dashboard--image-container-fish-icon"/>
                  <div style={{textAlign: "right"}}>
                    <p className="type--text"> This is equivalent to</p>
                    <p className="type--text strong">{fishToDisplayString}</p>
                  </div>
                </div>
              }
            </div>
            <div className="user-dashboard--button-container">
              <ScanButton text="Scan QR code" handleAlert={setAlertStatus} handleRewards={setRewardsList}/>
              {/*<Link className="user-dashboard--button button--partners" to="/restaurants">*/}
              {/*  <span className=""> Find cafés </span>*/}
              {/*</Link>*/}
            </div>
          </div>
      </Layout>
      </FixCoffeePathGameContext.Provider>
  )
};


const mapDispatchToProps = dispatch => ({
    createNewCup: () => dispatch(createNewCup()),
});

export default connect(null, mapDispatchToProps)(UserDashboard);

const TypeFormButtonContainer = styled.div`
  position: fixed;
  top: auto;
  right: 0;
  bottom: 20%;
  z-index: 10;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 1px 1px 0 0;
  cursor: pointer;
  transition: transform 0.15s ease-in 0s;
  transform-origin: right bottom;
  transform: rotate(270deg) translateY(2px);
`;
