import React from "react";

import AngleIcon from "./angleIcon";
import StraightLineIcon from "./straightLineIcon";

const Matrix = ({ rows, toggleCellActive }) => {
    if (!rows) {
        return null;
    }

    return (
        <>
            {rows.map((row, rowIndex) => (
                <div key={`${rowIndex}-row`} className="flex">
                    {row.map((cell, cellIndex) => {
                        return (
                            <div
                                key={`${cellIndex}-cell`}
                                className={`cell ${
                                    cell.isActive ? "border-purple" : "overflow-hidden border-green"
                                }`}
                                onClick={() => toggleCellActive(rowIndex, cellIndex)}
                                data-testid={`cell-${rowIndex}-${cellIndex}`}
                            >
                                {cell.cableType === "ANGLE" && (
                                    <div className={`angle-${cell.rotationDegree}`}>
                                        <AngleIcon
                                            data-testid="svg-angle"
                                            width="100px"
                                            height="100px"
                                            color={`${cell.isActive ? "#8c547a" : "#8DD4A1"}`}
                                        />
                                    </div>
                                )}
                                {cell.cableType === "STRAIGHT" && (
                                    <div className={`straight-${cell.rotationDegree}`}>
                                        <StraightLineIcon
                                            data-testid="svg-straight"
                                            width="100px"
                                            height="100px"
                                            color={`${cell.isActive ? "#8c547a" : "#8DD4A1"}`}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            ))}
        </>
    );
};

export default Matrix;
