import { combineReducers } from 'redux';
import restaurantsReducer from './restaurants_reducer';
import restaurantReducer from './restaurant_reducer';
import ordersReducer from './orders_reducer';
import filteredRestaurantsReducer from './filtered_restaurants_reducer';
import filtersReducer from './filters_reducer';
import menuItemsReducer from './menu_items_reducer';
import orderReducer from './order_reducer';
import orderItemsReducer from './order_items_reducer';


const entitiesReducer = combineReducers({
  restaurants: restaurantsReducer,
  restaurant: restaurantReducer,
  orders: ordersReducer,
  filteredRestaurants: filteredRestaurantsReducer,
  filters: filtersReducer,
  menuItems: menuItemsReducer,
  order: orderReducer,
  orderItems: orderItemsReducer,
});

export default entitiesReducer;
