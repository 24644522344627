import React from 'react';
import { Link } from 'react-router-dom';

import { navigationLinks } from '../../helpers/navigationLinks';

const NavigationItems = ({ isMobile = false }) => {
    return (
        <ul className="navigation-item-container">
            {
                isMobile ? (
                    <li className="navigation-item-container--list-item">
                        <Link id="home" className="navigation-item-container--item" to="/">Home</Link>
                    </li>
                ) : null
            }   
            {
                navigationLinks().map( ({text, slug}, index) => (
                    <li className="navigation-item-container--list-item" key={`navigation-item-${index}`}>
                        <a id={slug} className="navigation-item-container--item" href={slug}>{text}</a>
                    </li>
                ))
            }
        </ul>
    )
}

export default NavigationItems;
