import React from 'react';
import {Marker, InfoWindow} from '@react-google-maps/api';
import { Close } from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import styled from 'styled-components';

import {getOpeningMessage} from "../../../util/openingHours";
import OrderCoffeeLink from '../../../shared/components/order-coffee-link';
import {distanceInKmBetweenEarthCoordinates} from "../../../util/distance";

const CustomRestaurantMarker = ({ marker, isInfoWindownOpen, onHandleClick, onHandleClose }) => {
    const infoWindowPosition = {
        lat: marker.latitude,
        lng: marker.longitude
    };

    const openingMessage = getOpeningMessage({
        openingTime: marker.open_time,
        closingTime: marker.close_time
    })

    let distance = "? km"
    if (marker.isGeolocationAvailable && marker.coords) {
        const {latitude, longitude} = marker.coords;
        distance = distanceInKmBetweenEarthCoordinates(latitude, longitude,
            marker.latitude, marker.longitude) + " km"
    }

    return (
        <>
            <Marker
                position={{
                    lat: marker.latitude,
                    lng: marker.longitude
                }}
                icon={"/assets/images/pin-map.svg"}
                onClick={onHandleClick}
            >
                {
                    isInfoWindownOpen && (
                        <InfoWindow
                            position={infoWindowPosition}
                            onCloseClick={onHandleClose}>
                            <div className="info-window--container">
                                <CloseContainer className="info-window--close" onClick={onHandleClose} >
                                    <Close />
                                </CloseContainer>
                                <h3 className="info-window--name margin-bottom type--header-h2">{marker.name}</h3>
                                <p className="info-window--address">{marker.address} <Chip label={`${distance} away`} size="small" /></p>
                                <div className="info-window--hours flex">
                                    <div dangerouslySetInnerHTML={{__html: `${openingMessage}`}} />
                                </div>
                                <OrderCoffeeLink url={`/restaurants/${marker._key}`} color="white" />
                            </div>
                        </InfoWindow>
                    )
                }
            </Marker>
        </>
    );
};

const CloseContainer = styled.div`
    text-align: right;
`

export default CustomRestaurantMarker;
