import React from "react";
import styled from "styled-components";
import {rem} from "../../helpers/mixins";

const StyledCup = styled.img`
    width: ${rem(55)};
    height: ${rem(55)};
    background: url('/assets/images/circle.svg') no-repeat;
    margin: 8px 8px 8px 0;
    padding: 12px;
`;

const Stamp = ({n, t}) => {

    const cupArray = new Array(n).fill(null);

    return (
        <div className="flex flex-row flex-wrap">
            {cupArray.map((_, i) => (
                <StyledCup
                    key={i}
                    src={i < t ? "/assets/images/logo_mini.svg" : '/assets/images/circle.svg'}
                    alt=""/>
            ))}

        </div>
    )
}

export default Stamp


