import { merge } from 'lodash';
import { FETCH_SUCCESS_ORDERS, FETCH_FAILURE_ORDERS, FETCH_START_ORDERS } from '../actions/restaurant_actions';
import { saveOrders } from '../session_storage/session_storage';

const defaultState = {
  data: {},
  isLoading: false,
  error: {}
};

const ordersReducer = (oldState = defaultState, action) => {
  Object.freeze(oldState);
  switch(action.type) {
    case FETCH_SUCCESS_ORDERS:
      const newState = merge({}, oldState);
      saveOrders(action.orders);
      return {
        ...newState,
        data: action.orders,
        isLoading: false,
      };
    case FETCH_START_ORDERS: {
      const newState = merge({}, oldState);
      return {
        ...newState,
        isLoading: true,
      }
    }
    case FETCH_FAILURE_ORDERS: {
      const newState = merge({}, oldState);
      return {
        ...newState,
        isLoading: false,
        error: action.payload,
      }
    }
    default:
      return oldState;
  }
};

export default ordersReducer;
