import {useMutation} from "react-query";
import React, {useEffect, useMemo, useState} from "react";

import {addGameTimeToLeaderboard} from "../../api/add-time-leaderboard-game";
import {ActionType, useFixCoffeePathGameContext} from "../../actions/gameboard-context";
import LoginStore from "../../store/LoginStore";
import {calcSecondsFromTime} from "../gameboard/timer";
import Message from "../gameboard/message";


export const useAddGameTimeToLeaderboardMutation = ({handleGameOver, setErrorMessageLeaderBoardNotAvailable}) => {
    const { dispatch } = useFixCoffeePathGameContext();

    return useMutation({
        mutationFn: addGameTimeToLeaderboard,
        onSuccess: (score) => {
            dispatch({
                type: ActionType.SET_LEADERBOARD_GAME,
                payload: score,
            });
            handleGameOver();
        },
        onError: () => {
           setErrorMessageLeaderBoardNotAvailable(true);
        },
    });
};

function removeAfterAt(inputString) {
    if(!inputString) return;
    const atIndex = inputString.indexOf('@');

    if (atIndex !== -1) {
        return inputString.substring(0, atIndex);
    } else {
        return inputString;
    }
}

const LeaderboardGame = () => {
    const user = LoginStore.getUser();
    const { state } = useFixCoffeePathGameContext();
    const [filter, setFilter] = useState("");
    const { scores } = state;

    const [
        errorMessageLeaderBoardNotAvailable,
        setErrorMessageLeaderBoardNotAvailable,
    ] = useState(false);

    const {
        mutate: addGameTimeToLeaderboard,
        isPending: isLoadingAddEntryToLeaderboard,
    } = useAddGameTimeToLeaderboardMutation({
        handleGameOver: () => {
        },
        setErrorMessageLeaderBoardNotAvailable,
    });

    useEffect(() => {
        const leaderboardEntry = {
            user: user.id,
            seconds: calcSecondsFromTime(state.startTime, state.endTime),
        };

        addGameTimeToLeaderboard(leaderboardEntry);
    }, []);

    const filteredScores = useMemo(() => {
        return scores?.length > 0 && scores.filter((score) =>
            score.name.toLowerCase().includes(filter.toLowerCase())
        );
    }, [scores, filter]);

    const userIndex = useMemo(() => {
        return filteredScores?.length > 0 && filteredScores.findIndex((score) => score.user_id === user._key);
    }, [filteredScores, user.id]);

    const leaderboardEntries = useMemo(() => {
        let entries = Array.isArray(filteredScores) ? [...filteredScores] : [];
        if (userIndex === -1 && entries.length < 10) {
            entries.push({ id: user.id, seconds: calcSecondsFromTime(state.startTime, state.endTime) });
        }
        return entries;
    }, [filteredScores, userIndex, state.startTime, state.endTime, user.id]);

    return (
        <div className="flex flex-direction-column">
            <Message messageText="Every Monday at 12:00, the quickest finger wins a €10 coffee voucher at Back to Black in Amsterdam." />
            <Message messageText="We'll announce the winner on Instagram and email them. Stay tuned and good luck!" />
            <div className="rounded-leaderboard">
                <h2 className="type--header-h2">Leaderboard</h2>
                <div className="margin-top">
                    {leaderboardEntries.map((score, index) => (
                        <div key={`leaderboardscore-${score.user_id}-${index + 1}`} className="leaderboard-entry">
                            <span className={`type--text ${index === userIndex ? 'strong' : ''}`}>
                                {index + 1}. {score.email}
                            </span>
                            <span className="type--text strong">{score.seconds}sec</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LeaderboardGame;
