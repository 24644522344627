import qs from 'querystring';
import superagent from "superagent";

export const fetchRestaurants = async (query) => {
    const url = `${window.GO_API_ENDPOINT}/v1/list/cafes${query ? `?${qs.encode(query)}` : ''}`
    try {
        const response = await superagent
            .get(url)
            // .withCredentials();
        return response.body;
    } catch (err) {
        console.error("fetchRestaurants failed to call")
        return []
    }
};

export const fetchRestaurant = async ({id}) => {
    const url = `${window.GO_API_ENDPOINT}/v1/read/cafes/${id}`
    try {
        const response = await superagent
            .get(url)
            // .withCredentials();
        return response.body;
    } catch (err) {
        console.error("fetchRestaurant failed to call")
        return {}
    }
};


export const fetchRestaurantsLatLong = async (location, active) => {
    const url = `${window.API_ENDPOINT}/api/restaurants?lat=${location.lat}&lng=${location.lng}&active=${active}`
    try {
        const response = await superagent
            .get(url)
            // .withCredentials();
        return response.body;
    } catch (err) {
        console.error("fetchRestaurantsLatLong failed to call")
        return []
    }
};

export const fetchOrdersAPI = async () => {
    const url = `${window.GO_API_ENDPOINT}/v1/list/myorders`
    try {
        const response = await superagent
            .get(url)
            .withCredentials();
        return response.body;
    } catch (err) {
        console.error("fetchOrdersAPI failed to call")
        return []
    }
};


export const fetchMenuItemsAPI = async (id) => {
    const url = `${window.GO_API_ENDPOINT}/v1/list/items?place_id=${id}`
    try {
        const response = await superagent
            .get(url)
            // .withCredentials();
        return response.body;
    } catch (err) {
        console.error("fetchOrdersAPI failed to call")
        return []
    }
};

