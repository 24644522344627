import React from "react";

const StraightLineIcon = (props) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-22.5 -23.5 45 45"
        >
            <g transform="rotate(90)">
                <path strokeWidth="2" d="M0-50V50" stroke={props.color} />
            </g>
        </svg>
    );
};

export default StraightLineIcon;
