import React, { useEffect, useState } from "react";

import {useFixCoffeePathGameContext} from "../../actions/gameboard-context";

export const calcSecondsFromTime = (
    startTime,
    endTime = Date.now(),
) => Math.floor((endTime - startTime) / 1000);

const Timer = () => {
    const { state } = useFixCoffeePathGameContext();
    const { status, startTime } = state;
    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
        let interval = null;

        if (status === "running") {
            interval = setInterval(() => {
                setCurrentTime(calcSecondsFromTime(startTime));
            }, 1000);
        } else if (interval) {
            clearTimeout(interval);
        }

        return () => {
            if (interval) clearTimeout(interval);
        };
    }, [startTime, status]);

    return (
        <div data-testid="timer" className="type--text green margin-bottom" style={{ "transform": "translateY(12px)", "marginRight": "55px"}}>
            Duration: <b>{currentTime}s</b>
        </div>
    );
};

export default Timer;
