export const breakpoints = {
    maxWidth: {
        xs: 374,
        s: 576,
        m: 768,
        l: 992,
        xl: 1200,
        xxl: 1440,
    },
    minWidth: {
        xs: 375,
        s: 577,
        m: 769,
        l: 993,
        xl: 1201,
        xxl: 1441,
    }
};
