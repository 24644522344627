import qs from "querystring";

const superagent = require('superagent');

export const fetchCup = async (cupId) => {
    const url = `${window.GO_API_ENDPOINT}/v1/read/cups/${cupId}`
    try {
        const response = await superagent
            .get(url)
            .withCredentials();
        return response.body;
    } catch (err) {
        console.error("fetchCup failed ", err)
        return {}
    }
}

export const fetchAllCupLocation = async (query) => {
    const url = `${window.GO_API_ENDPOINT}/v1/list/cup_location?${query ? `${qs.encode(query)}` : ''}`
    try {
        const response = await superagent
            .get(url)
            .withCredentials();
        return response.body;
    } catch (err) {
        console.error("fetchAllCupLocation failed ", err)
        return []
    }
};

export const fetchAllRewards = async (query) => {
    const url = `${window.GO_API_ENDPOINT}/v1/list/myrewards?${query ? `${qs.encode(query)}` : ''}`
    try {
        const response = await superagent
            .get(url)
            .withCredentials();
        return response.body;
    } catch (err) {
        console.error("fetchAllRewards failed ", err)
        return []
    }
};

export const fetchCupLocation = async (id) => {
    const url = `${window.GO_API_ENDPOINT}/v1/read/cup_location/${id}`
    try {
        const response = await superagent
            .get(url)
            .withCredentials();
        return response.body;
    } catch (err) {
        console.error("fetchCupLocation failed ", err)
        return {}
    }
};

export const createCupLocation = async (data) => {
    const url = `${window.GO_API_ENDPOINT}/v1/create/cup_location`
    try {
        const response = await superagent
            .post(url)
            .withCredentials()
            .send(data)
            .set('Content-Type', 'application/json');
        return response.body;
    } catch (err) {
        console.error("createCupLocation failed ", err)
        return {}
    }
};

export const updateCupLocation = async (data) => {
    const url = `${window.GO_API_ENDPOINT}/v1/update/cup_location`
    try {
        const response = await superagent
            .post(url)
            .withCredentials()
            .send(data)
            .set('Content-Type', 'application/json');
        return response.body;
    } catch (err) {
        console.error("updateCupLocation failed ", err)
        return {}
    }
};

export const createCup = async (data) => {
    const url = `${window.GO_API_ENDPOINT}/v1/create/cups`
    try {
        const response = await superagent
            .post(url)
            .withCredentials()
            .send(data)
            .set('Content-Type', 'application/json');
        return response.body;
    } catch (err) {
        console.error("createCup failed ", err)
        return {}
    }
}

export const listAllCups = async (query) => {
    const url = `${window.GO_API_ENDPOINT}/v1/list/cups?${query ? `${qs.encode(query)}` : ''}`
    try {
        const response = await superagent
            .get(url)
            .withCredentials();
        return response.body;
    } catch (err) {
        console.log("Error, listAllCups, ", err)
        return []
    }
}

export const claimCup = async (data) => {
    const url = `${window.GO_API_ENDPOINT}/claim-rewards`
    try {
        const response = await superagent
            .post(url)
            .withCredentials()
            .send(data)
            .set('Content-Type', 'application/json');
        return response.body;
    } catch (err) {
        console.error("createCupLocation failed ", err)
        return {}
    }
};