import React from 'react';

const setRouteUrl = (name=null,lat=null,lng=null,address=null) => {
    const startString = 'https://google.com/maps?saddr=Current+Location&daddr=';
    // url in case of coordinates
    if( lat !== null && lng !== null && (lat !== 0 && lng !== 0) ) return `${startString}${ lat },${ lng }&amp;`;

    // if name and city are known
    if( name!== null && name !== '' && address.hasOwnProperty('city') && address.city !== null && address.city.hasOwnProperty('name') && address.city.name !== null ){
      const nameString = name.replace(/ /g,'+');
      const cityString = address.city.name.replace(/ /g, '+');

      // return url with either full address, or just the city
      return address !== null && address.street && address.houseNoFrom
        ? `${startString}${ nameString },+${ address.street.replace(/ /g,'+') }+${ address.houseNoFrom.replace(/ /g,'+') },+${ cityString },+Nederland&amp;`
        : `${startString}${ nameString },+${ cityString },+Nederland&amp;`;
    }

    // if not enough information is available
    return null;
}

const PlanRouteLink = ({ coordinates: { latitude, longitude, name, address } }) => {
    const mapLink = setRouteUrl(name, latitude, longitude, address);
    return (
        <a href={mapLink} target="_blank" rel="noopener noreferrer" className="plan-route-link type--small--strong">
            <img className="plan-route-link--icon" src="/assets/images/pin-map.svg" alt="pin icon" /> View route to {name}
        </a>
    )
}

export default PlanRouteLink;
