
export function getPossibleDirections(
    cableType,
    rotationDegree,
) {
    const direction = {
        STRAIGHT: {
            90: ["U", "D"],
            0: ["L", "R"],
        },
        ANGLE: {
            0: ["U", "R"],
            90: ["R", "D"],
            180: ["L", "D"],
            270: ["L", "U"],
        },
    };
    return direction[cableType][rotationDegree];
}

export function getNextDirection(cell, currentDirection) {
    const directions = getPossibleDirections(cell.cableType, cell.rotationDegree);

    if (directions && directions.length === 2) {
        return directions.find(
            (direction) => direction !== currentDirection,
        );
    }

    return "";
}

const directionMap = {
    L: { nextX: 0, nextY: -1, nextDirection: "R" },
    R: { nextX: 0, nextY: 1, nextDirection: "L" },
    U: { nextX: -1, nextY: 0, nextDirection: "D" },
    D: { nextX: 1, nextY: 0, nextDirection: "U" },
};

export function getNextCoordinate(
    currentCoordinates,
    nextDirection,
) {
    const change = directionMap[nextDirection];

    if (!change) {
        return currentCoordinates;
    }

    return {
        x: currentCoordinates.x + change.nextX,
        y: currentCoordinates.y + change.nextY,
        direction: change.nextDirection,
    };
}

export function isCableInCellConnectedInGivenDirection(
    matrix,
    coordinates,
) {
    const { x, y, direction } = coordinates;
    const cell = matrix[x]?.[y];

    const directions = getPossibleDirections(cell.cableType, cell.rotationDegree);

    return directions && directions.includes(direction);
}

function isMatrixValid(matrix) {
    return !!(matrix?.[0] ?? false);
}

function isOutOfBounds(x, y, matrix) {
    return x < 0 || x >= matrix.length || y < 0 || y >= matrix[0]?.length;
}

export function isLastCellAndConnectsToRight(
    matrix,
    coordinates,
    direction,
) {
    if (!isMatrixValid(matrix)) {
        return false;
    }

    const { x, y } = coordinates;
    const lastRowIndex = matrix.length - 1;
    const lastColumnIndex = matrix[0].length - 1;

    return x === lastRowIndex && y === lastColumnIndex && direction === "D";
}

export function checkIfCellExists(
    matrix,
    coordinates,
) {
    const { x, y } = coordinates;

    if (!isMatrixValid(matrix)) {
        return false;
    }

    if (isOutOfBounds(x, y, matrix)) {
        return false;
    }

    return !!matrix[x][y];
}

export function isCoffeePathValid(
    matrix,
    coordinates,
) {
    if (!isCableInCellConnectedInGivenDirection(matrix, coordinates)) {
        return false;
    }

    const cablePosition = matrix[coordinates.x][coordinates.y];
    let nextDirection = getNextDirection(cablePosition, coordinates.direction);

    if (isLastCellAndConnectsToRight(matrix, coordinates, nextDirection)) {
        return true;
    }

    const newCoordinates = getNextCoordinate(coordinates, nextDirection);

    if (!checkIfCellExists(matrix, newCoordinates)) {
        return false;
    }
    return isCoffeePathValid(matrix, newCoordinates);
}

export function generateMatrixWithActiveCells(
    matrix,
    coordinates,
) {
    const {x, y, direction} = coordinates;
    const cell = matrix[x]?.[y];

    if (isCableInCellConnectedInGivenDirection(matrix, coordinates)) {
        cell.isActive = true;
        matrix[x][y] = cell;
    } else {
        cell.isActive = false;
        matrix[x][y] = cell;
        return matrix;
    }

    const cablePosition = matrix[coordinates.x][coordinates.y];
    let nextDirection = getNextDirection(cablePosition, coordinates.direction);

    if (isLastCellAndConnectsToRight(matrix, coordinates, nextDirection)) {
        return matrix;
    }

    const newCoordinates = getNextCoordinate(coordinates, nextDirection);

    if (!checkIfCellExists(matrix, newCoordinates)) {
        return matrix
    }

    return generateMatrixWithActiveCells(matrix, newCoordinates);
}