import { merge } from 'lodash';
import { ADD_ITEM, DELETE_ITEM, DELETE_ALL_ITEMS, CREATE_ORDER_START, CREATE_ORDER_SUCCESS } from '../actions/order_item_actions';
import { saveOrder } from '../session_storage/session_storage';

const defaultState = {
  restaurantId: null,
  restaurantName: null,
  subtotal: 0,
  deliveryMinimum: 0,
  deliveryFee: 0,
  tax: 0,
  tip: null,
  total: 0,
  orderItemIds: [],
  order_items_attributes: [],
  isLoading: false,
};

const orderReducer = (oldState = defaultState, action) => {
  Object.freeze(oldState);
  switch(action.type) {
    case ADD_ITEM:
      const newState = merge({}, oldState);

      if (newState.restaurantId  !== action.restaurantId) {
        newState.restaurantId = action.restaurantId;
        newState.restaurantName = action.restaurantName;
        newState.deliveryMinimum = action.deliveryMinimum;
        newState.deliveryFee = action.deliveryFee;
        newState.subtotal = 0;
        newState.orderItemIds = [];
        newState.order_items_attributes = [];
      }

      newState.subtotal = newState.subtotal + (parseFloat(action.price) * action.quantity);
      newState.deliveryFee = (action.deliveryFee ? action.deliveryFee : 0);
      newState.tax = (newState.subtotal * 0.09).toFixed(2)*1;
      newState.total = (newState.subtotal + newState.deliveryFee + newState.tax).toFixed(2)*1;
      newState.orderItemIds.push(action.id);
      newState.order_items_attributes.push({
            menu_item_id:action.id,
            menu_item_name: action.name,
            quantity: action.quantity+"",
            item_instructions: action.itemInstructions,
            price: action.price,
          });

      saveOrder(newState);
      return newState;
    case DELETE_ITEM:
      const modifiedState = merge({}, oldState);

      modifiedState.orderItemIds = modifiedState.orderItemIds.filter(e => e !== action.id);
      modifiedState.order_items_attributes = modifiedState.order_items_attributes.filter(e => e.menu_item_id !== action.id);

      if (modifiedState.orderItemIds.length === 0) {
        saveOrder(defaultState);
        return defaultState;
      } else {
        modifiedState.subtotal = modifiedState.subtotal - (parseFloat(action.price) * action.quantity);
        modifiedState.tax = (modifiedState.subtotal * 0.09).toFixed(2)*1;
        modifiedState.total = (modifiedState.subtotal + modifiedState.deliveryFee + modifiedState.tax).toFixed(2)*1;
        saveOrder(modifiedState);
        return modifiedState;
      }
      break;
    case DELETE_ALL_ITEMS:
      saveOrder(defaultState);
      return defaultState;
    case CREATE_ORDER_START: {
      const newState = merge({}, oldState);
      return {
        ...newState,
        isLoading: true,
      }
    }
    case CREATE_ORDER_SUCCESS: {
      const newState = merge({}, oldState);
      return {
        ...newState,
        isLoading: false,
      }
    }
    default:
      return oldState;
  }
};

export default orderReducer;
