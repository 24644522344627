import superagent from "superagent";

export const fetchGeocode = place_id => {
  /*eslint-disable no-undef*/
  return new Promise(function(resolve, reject) {
    superagent.get(`https://maps.googleapis.com/maps/api/geocode/json`)
        .query({ place_id: place_id, key: 'AIzaSyAqtvhv4IAOGDQsDzXmu4ejQPArzomcHlU' })
        .end((err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res.body);
          }
        });
  });
};

export const fetchGeocodeByLatLng = (lat,lng) => {
  return new Promise((resolve, reject) => {
    superagent.get('https://maps.googleapis.com/maps/api/geocode/json')
        .query({ latlng: `${lat},${lng}`, key: 'AIzaSyAqtvhv4IAOGDQsDzXmu4ejQPArzomcHlU' })
        .end((err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res.body);
          }
        });
  });
};

export const fetchGeocodeByAddress = (address) => {
    return new Promise((resolve, reject) => {
        superagent.get('https://maps.googleapis.com/maps/api/geocode/json')
            .query({ address: address, key: 'AIzaSyAqtvhv4IAOGDQsDzXmu4ejQPArzomcHlU' })
            .end((err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res.body);
                }
            });
    });
};
