import {fetchMenuItemsAPI, fetchRestaurant as fetchRestaurantapi} from "../api";

export const RECEIVE_QUANTITY_ERRORS = 'RECEIVE_QUANTITY_ERRORS';
export const RECEIVE_ITEM_INSTRUCTIONS_ERRORS = 'RECEIVE_ITEM_INSTRUCTIONS_ERRORS';
export const RECEIVE_REQUIRED_OPTIONS_ERRORS = 'RECEIVE_REQUIRED_OPTIONS_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const FETCH_START_RESTAURANT = 'FETCH_START_RESTAURANT';
export const FETCH_SUCCESS_RESTAURANT = 'FETCH_SUCCESS_RESTAURANT';
export const FETCH_FAILURE_RESTAURANT = 'FETCH_FAILURE_RESTAURANT';

export const FETCH_START_MENU_ITEMS = 'FETCH_START_MENU_ITEMS';
export const FETCH_SUCCESS_MENU_ITEMS = 'FETCH_SUCCESS_MENU_ITEMS';
export const FETCH_FAILURE_MENU_ITEMS = 'FETCH_FAILURE_MENU_ITEMS';

export const receiveMenuItems = menuItems => {
    return ({
        type: FETCH_SUCCESS_MENU_ITEMS,
        menuItems
    });
};

export const receiveRestaurant = restaurant => {
    return ({
        type: FETCH_SUCCESS_RESTAURANT,
        restaurant
    });
};


export const receiveQuantityErrors = () => ({
    type: RECEIVE_QUANTITY_ERRORS,
    error: "Easy big fella! The maximum quantity is 99!"
});

export const receiveItemInstructionsErrors = () => ({
    type: RECEIVE_ITEM_INSTRUCTIONS_ERRORS,
    error: "The maximum length of instructions is 255 characters!"
});

export const receiveRequiredOptionsErrors = () => ({
    type: RECEIVE_REQUIRED_OPTIONS_ERRORS,
    error: "Some required options are missing! Please make a selection!"
});

export const clearErrors = () => ({
    type: CLEAR_ERRORS
});

export const fetchMenuItems = restaurantId => {
    return dispatch => {
        dispatch({type: FETCH_START_MENU_ITEMS});
        fetchMenuItemsAPI(restaurantId)
            .then((menuItems) => {
                dispatch(receiveMenuItems(menuItems))
            })
            .catch(error => {
                dispatch({type: FETCH_FAILURE_MENU_ITEMS, payload: error})
            });
    }
};

export const fetchRestaurant = id => {
    return dispatch => {
        dispatch({ type: FETCH_START_RESTAURANT });
        fetchRestaurantapi({id})
            .then((restaurant) => {
                dispatch(receiveRestaurant(restaurant));
            })
            .catch(error => {
                dispatch({ type: FETCH_FAILURE_RESTAURANT, payload: error });
            });
    };
};