import superagent from "superagent";

export const createOrder = (payload) => {
    return superagent
        .post(`${window.API_ENDPOINT}/api/orders`)
        .withCredentials()
        .send({ order: payload })
        .then(response => response.body);
};

export const createOrderPending = async (payload) => {

    const newObject = Object.assign({}, payload);


    newObject.deliveryFee = newObject.deliveryFee + ""
    newObject.deliveryMinimum = newObject.deliveryMinimum + ""
    newObject.subtotal = newObject.subtotal + ""
    newObject.tax = newObject.tax + ""
    newObject.tip = newObject.tip + ""
    newObject.total = newObject.total + ""
    newObject.restaurant_id = newObject.restaurantId

    // TODO: use JOI -> https://github.com/sideway/joi


    return superagent
        .post(`${window.GO_API_ENDPOINT}/orders`)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(newObject))
        .then(response => response.body);
};

export const orderStatus = payload => {
    return superagent
        .get(`${window.GO_API_ENDPOINT}/orders`)
        .withCredentials()
        .query({id: payload})
        .then(response => response.body);
};


