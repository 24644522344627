/** LOGIN COMPONENT **/

import jwt_decode from 'jwt-decode';
import {EventEmitter} from 'events';

class LoginStore extends EventEmitter {

    constructor() {
        super();
        this._user = null;
        this._error = null;
        this._jwt = null;
        this._cup = null;

        //attempt auto-login
        if (typeof window !== 'undefined') {
            console.log('&*&*&*& attempting auto-login in LoginStore');
            this._autoLogin();
        }
    }


    _autoLogin() {
        if (typeof window !== 'undefined') {
            let jwt = localStorage.getItem("jv_jwt");
            console.log('jwt',jwt);
            console.log('!jwt',!jwt);
            if (!jwt) {

                let vars = [], hash;
                const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
                for (let i = 0; i < hashes.length; i++) {
                    hash = hashes[i].split('=');
                    vars.push(hash[0]);
                    vars[hash[0]] = hash[1];
                }

                const session = vars["session"];
                const jv_jwt = vars["jv_jwt"];
                //console.log(session);

                //console.log(jv_jwt);
                if (jv_jwt) {
                    console.log("&*&*&* will login from req params");

                    localStorage.setItem("jv_jwt", jv_jwt);
                    this._jwt = jv_jwt;
                    this._user = jwt_decode(this._jwt);
                    console.log(this._user);
                    console.log("&*&*&* autologin success");

                } else {
                    console.log("&*&*&* failed to get session or jwt token :| --> " + window.location.href);
                }
            } else {
                console.log("&*&*&* will login from localStorage");
                this._jwt = jwt;
                console.log(this._jwt);
                console.log('jwt', JSON.stringify(this._jwt));
                // if (!jwt) {
                //     console.error('JWT token is missing.');
                //     // Handle the case where the JWT token is missing
                // } else if (!jwt.exp || jwt.exp < Date.now() / 1000) {
                //     console.error('JWT token is expired.');
                //     // Handle the case where the JWT token is expired
                // } else if (jwt.iss !== 'expected_issuer') {
                //     console.error('Invalid issuer.');
                //     // Handle the case where the issuer is not as expected
                // } else {
                    console.log('JWT token is valid.');
                    // Perform additional checks or proceed with using the token
                    this._user = jwt_decode(this.jwt);
                    console.log(this._user);
                    console.log("&*&*&* autologin success");
                // }


            }


            console.log("&*&*&* will remove credentials from url");
            //TODO: remove only session and jv_jwt from parameters, it may affect other pages in case we start using params :|

            if (window.parent.location.href.match(/session=/)) {
                // eslint-disable-next-line no-restricted-globals
                if (typeof (history.pushState) !== "undefined") {
                    var obj = {Title: document.title, Url: window.parent.location.pathname};
                    // eslint-disable-next-line no-restricted-globals
                    history.pushState(obj, obj.Title, obj.Url);
                } else {
                    // window.parent.location = window.parent.location.pathname;
                    window.history.pushState({}, document.title, window.location.pathname);
                }
            }

            const loginCallback = localStorage.getItem("login_callback");
            if (loginCallback) {
                console.log("&*&*&* going to redirect to login_callback", loginCallback)
                localStorage.setItem("login_callback", "");
                window.location.href = loginCallback
            }


        }
    }

    getUser() {
        return this._user;
    }

    get user() {
        return this._user;
    }

    get error() {
        return this._error;
    }

    get jwt() {
        return this._jwt;
    }

    isLoggedIn() {
        return !!this._user;
    }

    set cup(cup) {
        this._cup = cup
    }

    logout() {
        localStorage.clear();
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });

    }
}

function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}


export default new LoginStore();
