
export async function generateBoard(
    dimension,
) {
    try {
        const response = await fetch(
            `${window.GO_API_ENDPOINT}/board/generate?dimension=${dimension}`,
            {
                method: "POST",
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dimension),
            },
        );
        if (!response.ok) {
            throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        const cells = data.Cells.map((row) => {
              return row.map((cell) => ({...cell, isActive: false}))
            }
        );

        return { ...data, cells };
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(`HTTP error! Error: ${error.message}`);
        } else {
            throw new Error("An unknown error occurred during generateBoard");
        }
    }
}