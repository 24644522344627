import React from 'react';
import { ProtectedRoute } from '../util/route_util';
import { Route, Switch } from 'react-router-dom';

import RestaurantIndex from './restaurant_index/restaurant_index';
import UserDashboard from './user_dashboard';

import RestaurantShowContainer from './restaurant_show/restaurant_show_container';
import ErrorPage from './error_page';
import Login from "./login/login";
import RedirectPopup from "./redirect_popup";
import StampCardsList from "./stamp_cards/stamp-cards-list";

const App = () => {
  if (
      window.location.hostname !== "reusify.io" &&
      window.location.hostname !== "www.reusify.io" &&
      window.location.hostname !== "app.reusify.io" &&
      window.location.hostname !== "localhost" &&
      window.location.hostname !== "127.0.0.1"
  ) {
    return (
        <React.Fragment>
          <Switch>
            <Route exact path='/' component={RedirectPopup}/>;
          </Switch>
        </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Switch>
        <Route exact path='/restaurants/:id' component={ RestaurantShowContainer }/>
        <Route exact path='/restaurants' component={ RestaurantIndex }/>
        <ProtectedRoute exact path='/' component={ UserDashboard } />
        {/*<ProtectedRoute exact path='/user/:id/impact' component={ UserDashboard } />*/}
        {/*<ProtectedRoute exact path='/user/:id/:restaurant_id/impact' component={ UserDashboard } />*/}
        {/*<ProtectedRoute exact path='/user/:id/my-stamp-cards' component={ StampCardsList }/>*/}
        <Route exact path='/login' component={ Login } />
        <Route component={ ErrorPage } />
      </Switch>
    </React.Fragment>
  );
};

export default App;
