import superagent from "superagent";

export const fetchReviews = (restaurantId) => {
  return superagent
      .get(`${window.API_ENDPOINT}/api/restaurants/${restaurantId}/reviews`)
      .then(response => response.body);
};

export const createReview = (review) => {
  return superagent
      .post(`${window.API_ENDPOINT}/api/restaurants/${review.restaurant_id}/reviews`)
      .send({ review })
      .then(response => response.body);
};

export const fetchReviewable = (restaurantId) => {
  return superagent
      .get(`${window.API_ENDPOINT}/api/restaurants/${restaurantId}`)
      .then(response => response.body);
};
