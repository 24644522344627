import moment from 'moment';

export const NOW_OPEN = '<span class="strong"> Open Now </span>';
export const CLOSED = '<span class="link--grey strong"> Closed </span>';

export const OPENING_SOON = '<span class="strong"> Opening soon </span>';
// const OPEN_FOR_ONE_MORE_HOUR = module.exports.OPEN_FOR_ONE_MORE_HOUR = '<span class="link--green strong"> Nog een uur geopend </span>';

export const CLOSES_IN_X_MINS = '<span class="strong"> Closes in #MINS# minutes </span>';
// const REOPENS_IN_LESS_THAN_TEN_MINS = module.exports.REOPENS_IN_LESS_THAN_TEN_MINS = '<span class="link--green strong"> Heropend #LESS_THAN# #MINS# minuten </span>';

// const LESS_THAN = module.exports.LESS_THAN = '<span class="link--green strong"> minder dan </span>';
// export const OPEN_24H = '<span class="link--green strong"> 24 uur per dag open </span>';

export function isBusinessIsOpen(openingHours = [], timeNow = new Date()) {

    let openingMessage = '';
    let timeNowMoment = moment(timeNow);

    //no opening hours, then return
    if (!openingHours.length) {
        return openingMessage;
    }

    // Check if a moment is after or the same as another moment.
    const midNight = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate()} 00:00`, 'YYYY/MM/DD HH:mm');
    const isAfterMidnight = timeNowMoment.isSameOrAfter(midNight);

    // get the opening time
    let openingTimeMoment = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate()} ${openingHours[0].openingTime}`, 'YYYY/MM/DD HH:mm');

    // get closing time
    let closingTimeMoment = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate()} ${openingHours[0].closingTime}`, 'YYYY/MM/DD HH:mm');


    //special case for after midnight
    if (isAfterMidnight) {

        if (openingTimeMoment.isSameOrAfter(timeNowMoment)) {
            openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${OPENING_SOON}`;
            return openingMessage;
        }

        let isClosedAlready = closingTimeMoment.isSameOrAfter(timeNowMoment) && openingTimeMoment.isSameOrAfter(timeNowMoment);
        if (isClosedAlready) {
            openingMessage = `${CLOSED}`;
            return openingMessage;
        }
    }
    // get count in mins for first opening hours
    let timeToClose = closingTimeMoment.diff(timeNowMoment, 'minutes');

    if (closingTimeMoment.hour() < openingTimeMoment.hour()) {
        closingTimeMoment = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate() + 1} ${openingHours[0].closingTime}`, 'YYYY/MM/DD HH:mm');
        timeToClose = closingTimeMoment.diff(timeNowMoment, 'minutes');
    }

    if (!openingHours[0].closingTime && !openingHours[0].openingTime) {
        openingMessage = `${CLOSED}`;
        return openingMessage;
    }

    // if(closingTimeMoment.hour() === openingTimeMoment.hour()) {
    //   openingMessage = `${OPEN_24H}`;
    //   return openingMessage;
    // }

    if (openingHours.length === 2) {

        let openingTimeMomentSecondSlot = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate()} ${openingHours[1].openingTime}`, 'YYYY/MM/DD HH:mm');
        let timeToOpen = openingTimeMomentSecondSlot.diff(timeNowMoment, 'minutes');

        let closingTimeMomentSecondSlot = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate()} ${openingHours[1].closingTime}`, 'YYYY/MM/DD HH:mm');

        if (timeToOpen <= 60 && openingHours.length === 2) {

            // if (timeToOpen <= 10) {
            //   openingMessage = `${REOPENS_IN_LESS_THAN_TEN_MINS.replace('#LESS_THAN#', LESS_THAN).replace('#MINS#', timeToOpen)}`;
            //   return openingMessage;
            // }

            openingMessage = `${formatDate(openingTimeMomentSecondSlot, closingTimeMomentSecondSlot)} ${OPENING_SOON}`;
            return openingMessage;
        }
    }
    //status closed but only opening time if 00:00 closing hours than display msg is wrong
    if (timeToClose <= 0) {
        openingMessage = `${CLOSED}`;
        return openingMessage;
    }

    // if status open and opening
    if (timeToClose > 60) {
        openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${NOW_OPEN}`;
        return openingMessage;
    }

    if (timeToClose > 0) {

        // if(timeToClose <= 10) {
        //   openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${CLOSES_IN_X_MINS.replace(' #LESS_THAN#', LESS_THAN).replace('#MINS#', timeToClose)}`;
        //   return openingMessage;
        // }

        if (timeToClose < 60) {
            openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${CLOSES_IN_X_MINS.replace('#MINS#', timeToClose)}`;
            return openingMessage;
        }

        // if(timeToClose === 60){
        //   //return msg with 1h
        //   openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${OPEN_FOR_ONE_MORE_HOUR}`;
        //   return openingMessage;
        // }
    }
}

export function formatDate(openingTime, closingTime) {
    if (!openingTime || !closingTime) {
        return '';
    }

    if (typeof openingTime.format !== 'function' || typeof closingTime.format !== 'function') {
        let timeNow = new Date();
        openingTime = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate()} ${openingTime}`, 'YYYY/MM/DD HH:mm');
        closingTime = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate()} ${closingTime}`, 'YYYY/MM/DD HH:mm');
    }

    return `${openingTime.format('HH:mm')} - ${closingTime.format('HH:mm')}`;
}

export function getOpeningMessage(openingHours = {}, timeNow = new Date()) {

    let openingMessage = '';
    let timeNowMoment = moment(timeNow);

    //no opening hours, then return
    if (!openingHours || !openingHours.openingTime || !openingHours.closingTime) {
        return openingMessage;
    }

    const {openingTime, closingTime} = openingHours;

    // Check if a moment is after or the same as another moment.
    const midNight = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate()} 23:59`, 'YYYY/MM/DD HH:mm');
    const isAfterMidnight = timeNowMoment.isSameOrAfter(midNight);

    // get the opening time
    let openingTimeMoment = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate()} ${openingTime}`, 'YYYY/MM/DD HH:mm');

    // get closing time
    let closingTimeMoment = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate()} ${closingTime}`, 'YYYY/MM/DD HH:mm');

    //special case for after midnight
    if (isAfterMidnight) {
        if (openingTimeMoment.isSameOrAfter(timeNowMoment)) {
            openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${OPENING_SOON}`;
            console.log("getOpeningMessage, return ", openingMessage)
            return openingMessage;
        }

        let isClosedAlready = closingTimeMoment.isSameOrAfter(timeNowMoment) && openingTimeMoment.isSameOrAfter(timeNowMoment);
        if (isClosedAlready) {
            openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${CLOSED}`;
            console.log("getOpeningMessage, return ", openingMessage)
            return openingMessage;
        }
    }

    // get count in mins for first opening hours
    let timeToClose = closingTimeMoment.diff(timeNowMoment, 'minutes');

    if (closingTimeMoment.hour() < openingTimeMoment.hour()) {
        closingTimeMoment = moment(`${timeNow.getFullYear()}/${timeNow.getMonth() + 1}/${timeNow.getDate() + 1} ${closingTime}`, 'YYYY/MM/DD HH:mm');
        timeToClose = closingTimeMoment.diff(timeNowMoment, 'minutes');
    }

    if (!closingTime && !openingTime) {
        openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${CLOSED}`;
        console.log("getOpeningMessage, return ", openingMessage)
        return openingMessage;
    }

    //status closed but only opening time if 00:00 closing hours than display msg is wrong
    if (timeToClose <= 0) {
        openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${CLOSED}`;
        console.log("getOpeningMessage, return ", openingMessage)
        return openingMessage;
    }

    // if status open and opening
    if (timeToClose > 60) {
        openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${NOW_OPEN}`;
        console.log("getOpeningMessage, return ", openingMessage)
        return openingMessage;
    }

    if (timeToClose > 0) {

        // if(timeToClose <= 10) {
        //   openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${CLOSES_IN_X_MINS.replace(' #LESS_THAN#', LESS_THAN).replace('#MINS#', timeToClose)}`;
        //   return openingMessage;
        // }

        if (timeToClose < 60) {
            openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${CLOSES_IN_X_MINS.replace('#MINS#', timeToClose)}`;
            console.log("getOpeningMessage, return ", openingMessage)
            return openingMessage;
        }

        // if(timeToClose === 60){
        //   //return msg with 1h
        //   openingMessage = `${formatDate(openingTimeMoment, closingTimeMoment)} ${OPEN_FOR_ONE_MORE_HOUR}`;
        //   return openingMessage;
        // }
    }

}
