import React from 'react';
import LoginStore from "../../store/LoginStore";

export default class SessionModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            password: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleGuestLogin = this.handleGuestLogin.bind(this);
        this.toggleSignupModal = this.toggleSignupModal.bind(this);
        this.toggleSessionModal = this.toggleSessionModal.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        const user = Object.assign({}, this.state);
        if (this.props.signupModal) {
            this.props.signup(user);
        } else {
            const pathname = this.props.location.pathname;
            const index = pathname.search(/\d+/);
            if (index === -1) {
                this.props.login(user);
            } else {
                const id = pathname.slice(index);
                this.props.login(user).then(() => {
                    this.props.fetchReviewable(id);
                });
            }
        }
    }

    handleGuestLogin(e) {
        e.preventDefault();
        // set current page on localstorage
        console.log("&*&*&* set current page on localstorage", window.location.href)
        localStorage.setItem("login_callback", window.location.href);

        if (document.location.hostname.indexOf('127.0.0.1') !== -1) {
            window.location.href = "http://127.0.0.1:3010/login?referer=http://127.0.0.1:3000"
        } else {
            window.location.href = "https://api.reusify.io/login?referer=https://www.reusify.io"
        }
    }

    toggleSignupModal() {
        this.props.toggleSignupModal();
        this.props.clearErrors();
    }

    toggleSessionModal(e) {
        if (e.target === e.currentTarget) {
            this.props.toggleSessionModal();
            this.props.clearErrors();
        }
    }

    update(field) {
        return (e) => {
            this.setState({[field]: e.target.value});
        };
    }

    render() {
        const {signupModal, errors, toggleSessionModal, toggleSignupModal} = this.props;

        const loggedIn = LoginStore.isLoggedIn();

        let guestSignIn;
        if (!loggedIn) {
            guestSignIn = <input id='guest-login' type='submit' className="secondary-button" value='Log In' onClick={this.handleGuestLogin}/>;
        } else {
            guestSignIn = <div/>
        }

        return (
            <div className='modal-container' onClick={this.toggleSessionModal}>
                <div className='session-modal '>
                    <img className='session-modal--logo' src={window.staticImages.logo} alt='Reusify.io'/>
                    <h4 className='type--paragraph margin-vertical-l'>{signupModal ? 'Create your account' : 'Please log in'}</h4>

                    <h6 className='errors'>{errors ? errors[0] : null}</h6>

                    <form className='session-form'>
                        {guestSignIn}
                    </form>

                    <button className="x-close" onClick={this.toggleSessionModal}>&times;</button>
                </div>
            </div>
        );
    }
}
