import { combineReducers } from 'redux';
import { FETCH_START_RESTAURANT, FETCH_SUCCESS_RESTAURANT, FETCH_FAILURE_RESTAURANT } from '../actions/menu_item_actions';

const initialState = {
  data: {},
  isLoading: false,
  error: {}
};

const data = (state = initialState.data, action) => {
  Object.freeze(state);
  switch(action.type) {
    case FETCH_SUCCESS_RESTAURANT:
      return action.restaurant;
    case FETCH_START_RESTAURANT:
    case FETCH_FAILURE_RESTAURANT:
      return initialState.data;
    default:
      return state;
  }
};

const isLoading = (state = initialState.isLoading, action) => {
  switch(action.type) {
    case FETCH_START_RESTAURANT:
      return true;
    case FETCH_SUCCESS_RESTAURANT:
    case FETCH_FAILURE_RESTAURANT:
      return initialState.isLoading;
    default:
      return state;
  }
};

const error = (oldState = initialState.error, action) => {
  switch(action.type) {
    case FETCH_FAILURE_RESTAURANT:
      return action.payload;
    case FETCH_START_RESTAURANT:
    case FETCH_SUCCESS_RESTAURANT:
      return initialState.error
    default:
      return oldState;
  }
};


export default combineReducers({
  data,
  isLoading,
  error
});
