import React from 'react';
import Media from 'react-media';
import { StickyContainer, Sticky } from 'react-sticky';

import { Navigation, NavigationMobile } from '../navigation';
import Footer from '../footer';

import { breakpoints } from "../../helpers/breakpoints";

const Layout = ({ children, withFooter = true }) => {
    return (
        <div className="container">
            <Media
                query={{ maxWidth: breakpoints.maxWidth.m }}
                render={() =>
                    <StickyContainer>
                        <Sticky topOffset={3} disableHardwareAcceleration>
                            {({style, isSticky}) => (
                                <div style={style} className={`${isSticky ? 'navigation-sticky' : 'navigation-sticky-container'}`}>
                                    <NavigationMobile />
                                </div>
                            )}
                        </Sticky>
                        <div style={{ position: 'relative' }}>
                            { children }
                        </div>
                        { withFooter && <Footer /> }                        
                    </StickyContainer>
                }
            />
            <Media
                query={{ minWidth: breakpoints.minWidth.m }}
                render={() => <>
                        <Navigation />
                        <div style={{ position: 'relative' }}>
                            { children }
                        </div>
                        { withFooter && <Footer /> }
                    </>
                }
            />
        </div>
    );
};

export default Layout;
