import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {geolocated} from "react-geolocated";

import {getOpeningMessage} from "../../util/openingHours";
import {distanceInKmBetweenEarthCoordinates} from "../../util/distance";
import {MapRounded, WatchLaterOutlined} from "@material-ui/icons";
import {createCup, createCupLocation, listAllCups} from "../../api";
import LoginStore from "../../store/LoginStore";
import * as OrderUtil from "../../util/order_util";
import {useQueryClient} from "react-query";
import Loading from "../loading";


const RestaurantIndexSuggestion = props => {
    const queryClient = useQueryClient();
    const loggedIn = LoginStore.isLoggedIn();
    const userId = loggedIn && LoginStore.user._key
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const { name, address, img_url, open_time, close_time} = props.restaurant;
    const openingMessage = getOpeningMessage({
        openingTime: open_time,
        closingTime: close_time
    })

    let distance = "? km"
    if (props.isGeolocationAvailable && props.location) {
        const {lat: latitude, lng: longitude} = props.location;
        distance = distanceInKmBetweenEarthCoordinates(latitude, longitude,
            props.restaurant.latitude, props.restaurant.longitude) + " km"
    }

    const handleSubmitCupCheckout = async (restaurant) => {
        try {
            setIsLoading(true);

            let mycups = await listAllCups({
                user_id: LoginStore.user._key,
                status: 'MY_CUP',
                _sort: 'updated_at',
                _order: 'DESC',
            })

            if (loggedIn && mycups.length === 0) {
                const newCup = {
                    brand: "smidge",
                    cost: 12.99,
                    description: "New cup",
                    operator: "",
                    status: 'MY_CUP',
                    user_id: LoginStore.user._key,
                    size: "medium"
                }
                await createCup(newCup);
                mycups = await listAllCups({
                    user_id: LoginStore.user._key,
                    status: 'MY_CUP',
                    _sort: 'updated_at',
                    _order: 'DESC',
                })
            }

            if (!restaurant || !restaurant._key) {
                console.error("ERROR, handleQrCodeScan, failed to scan cup ", restaurant);
                return
            }

            const cup_id = mycups[0]._key
            const restaurant_id = restaurant._key

            // add cup location entry for given restaurant id and user id
            // { user:id: current_user_id, cup_id: current_user.cup._key }
            // await addCupToUser({restaurant_id: restaurant._key, cup_id: })

            const newOrder = {
                status: "IN_TRANSIT",
                deliveryFee: "1",
                deliveryMinimum: "1",
                orderItemIds: ["15769719"],
                order_items_attributes: [
                    {
                        item_instructions: "",
                        menu_item_id: "15769719",
                        menu_item_name: "Espresso",
                        price: "2.50",
                        quantity: "1",
                    }
                ],
                restaurant_id: restaurant_id,
                restaurantId: restaurant_id,
                subtotal: "2.5",
                tax: "0.22",
                tip: "null",
                total: "3.72",
            }

            const orderRequest = await OrderUtil.createOrderPending(newOrder);
            if (!orderRequest || !orderRequest._key) {
                console.log("error createOrderPending, failed to create order")
                return
            }

            const cupLocation = {
                user_id: LoginStore.user._key,
                cup_id: cup_id,
                order_id: orderRequest._id,
                status: "IN_TRANSIT",
                restaurant_id: restaurant_id,
            }

            const databack = await createCupLocation(cupLocation);
            await queryClient.invalidateQueries(['cup_location_by_user_id', {user_id: userId}]);
            await queryClient.invalidateQueries(['user_cup_rewards_by_user_id', {user_id: userId}]);
            await queryClient.invalidateQueries(['all_cup_location_count']);
            await queryClient.invalidateQueries(['cup_location_count_by_user_id', userId]);
            await queryClient.invalidateQueries(['cup_location_count_by_user_id', {user_id: userId}]);
            if (!databack || !databack._key) {
                console.log("error createCupLocation, failed to cup location", cupLocation)
                return
            }
            console.log("createCupLocation, result ", databack)
            setIsLoading(false);

            history.push(`/user/${userId}/impact?msg=success`);
            props.close()
            props.handleAlert && props.handleAlert('success')
        } catch (e) {
            setIsLoading(false);
            props.handleAlert && props.handleAlert('error')
            console.error("ERROR, handleQrCodeScan, ", e);
        }
    };

    return (
        <li className='restaurant-index-item' onClick={() => {
            handleSubmitCupCheckout(props.restaurant);
        }}>
            <Loading isLoading={
                isLoading
            }/>
            <div className='restaurant-index-item--index-left-side' style={{"border": "1px solid #5e2958", "borderRadius": "1px"}}>
                <div className='restaurant-index-item--small-restaurant-image'
                     style={{
                         backgroundImage: `url(${img_url ? img_url : '"/assets/images/cups-connecting.svg"'})`,
                         backgroundRepeat: 'no-repeat'
                     }}/>
                <div className='restaurant-index-item--index-restaurant-cuisine-container'>
                    <div className="flex align-items-center justify-content-between">
                        <h3 className="type--header-h2">{name}</h3>
                        {/*{price &&*/}
                        {/*    <div className="flex align-items-center margin-left-l">*/}
                        {/*        <LocalOffer/>*/}
                        {/*        <span className="type--label"> {price} off with reusable cup </span>*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </div>
                    <label className="type--small--strong margin-vertical-xs">{address}</label>
                    <span className="type--text flex align-items-center"><MapRounded/>{`${distance} away`}</span>
                    <div className="type--text flex align-items-center">
                        <WatchLaterOutlined/>
                        <div dangerouslySetInnerHTML={{__html: `${openingMessage}`}}/>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(RestaurantIndexSuggestion);
