import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Media from 'react-media';

import { logout, clearErrors } from '../../actions/session_actions';
import { toggleSessionModal } from '../../actions/modal_actions';

import SessionModalContainer from '../modals/session_modal_container';

import LoginStore from '../../store/LoginStore';

import { breakpoints } from "../../helpers/breakpoints";
class LogUser extends React.Component {
    constructor(props) {
        super(props);

        this.toggleSessionModal = this.toggleSessionModal.bind(this);
    }

    handleLogout = e => {
        e.preventDefault();
        LoginStore.logout()
        // this.props.logout();
        if (document.location.hostname.indexOf('127.0.0.1') !== -1) {
            window.location.href = "http://127.0.0.1:3010/logout?referer=http://127.0.0.1:3000"
        } else {
            window.location.href = "https://api.reusify.io/logout?referer=https://app.reusify.io"
        }
    }

    toggleSessionModal() {
        this.props.clearErrors();
        // this.props.toggleSessionModal();
        if (document.location.hostname.indexOf('127.0.0.1') !== -1) {
            window.location.href = "http://127.0.0.1:3010/login"
        } else {
            window.location.href = "https://api.reusify.io/login?referer=https://app.reusify.io"
        }
    }

    render() {
        const { sessionModal } = this.props;
        const loggedIn = LoginStore.isLoggedIn();

        return (
            <div className="log-user-container">
                {
                    LoginStore.user && LoginStore.user.avatarurl ? (
                        <img className="log-user-container--user-avatar" src={LoginStore.user.avatarurl} alt='userAvatar'/>
                    ) : (
                        <div className={`log-user-container--user-icon ${loggedIn ? 'log-user-container--user-icon-logged' : ''}`} />
                    )
                }
                <ul className="navigation-item-container log-user-navigation-list">
                    <li className="navigation-item-container--list-item">
                        {
                            loggedIn ? (
                                <>
                                    <Link className="navigation-item-container--item" to={`/`}>{LoginStore.user.name}</Link>
                                </>
                            ) : <button className='navigation-item-container--item' onClick={this.handleLogout}>Log in or sign up</button>
                        }
                    </li>
                    {
                        <Media
                        query={{ minWidth: breakpoints.minWidth.m }}
                        render={() => (
                                loggedIn && (
                                    <li className="navigation-item-container--list-item">
                                        <button className="navigation-item-container--item" onClick={this.handleLogout}>Log out</button>
                                    </li>
                                )
                            )}
                        />
                    }                    
                </ul>
                 {sessionModal ? <SessionModalContainer/> : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.session.currentUser,
    sessionModal: state.ui.modals.sessionModal,
    signupModal: state.ui.modals.signupModal
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    toggleSessionModal: () => dispatch(toggleSessionModal()),
    clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(LogUser);
