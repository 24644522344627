import { combineReducers } from 'redux';
import { FETCH_SUCCESS_ADDRESS, FETCH_START_ADDRESS, FETCH_FAILURE_ADDRESS, UPDATE_ADDRESS } from '../actions/address_actions';
import { saveAddress, updateAddress } from '../session_storage/session_storage';

const initialState = {
    data: sessionStorage.getItem('address') 
      ? JSON.parse(sessionStorage.getItem('address')) 
      : {
        formattedAddress: null,
        location: null,
        addressComponents: null,
        place_id: null
    },
    isLoading: false,
    error: {},
};

const data = (state = initialState.data, action) => {
  Object.freeze(state);
  switch(action.type) {
    case FETCH_SUCCESS_ADDRESS: {
      const {formatted_address, address_components, place_id} = action.geocodeResponse.results[0];
      const { location } = action.geocodeResponse.results[0].geometry;
      const newState = ({
          formattedAddress: formatted_address,
          location: location,
          addressComponents: address_components,
          place_id: place_id
      });
      saveAddress(newState);
      return newState;
    }
    case UPDATE_ADDRESS: {
      const {formatted_address, address_components, place_id, geometry: { location }} = action.payload;
      const newState = ({
          formattedAddress: formatted_address,
          location: {
            lat: location.lat(),
            lng: location.lng(),
          },
          addressComponents: address_components,
          place_id: place_id
      });
      updateAddress(newState);
      return newState;
    }
    case FETCH_START_ADDRESS:
    case FETCH_FAILURE_ADDRESS:
      return initialState.data;
    default:
      return state;
  }
};

const isLoading = (state = initialState.isLoading, action) => {
  switch(action.type) {
    case FETCH_START_ADDRESS:
      return true;
    case FETCH_SUCCESS_ADDRESS:
    case FETCH_FAILURE_ADDRESS:
      return initialState.isLoading;
    default:
      return state;
  }
};

const error = (oldState = initialState.error, action) => {
  switch(action.type) {
    case FETCH_FAILURE_ADDRESS:
      return action.payload;
    case FETCH_START_ADDRESS:
    case FETCH_SUCCESS_ADDRESS:
      return initialState.error
    default:
      return oldState;
  }
};

export default combineReducers({
  data,
  isLoading,
  error,
});

// const addressReducer = (prevState = null, action) => {
//     Object.freeze(prevState);
//     switch (action.type) {
//         case GEOCODE:
//             if (action.geocodeResponse && action.geocodeResponse.results && action.geocodeResponse.results[0]) {
//                 const {formatted_address, address_components, place_id} = action.geocodeResponse.results[0];
//                 const { location } = action.geocodeResponse.results[0].geometry;
//                 const newState = ({
//                     formattedAddress: formatted_address,
//                     location: location,
//                     addressComponents: address_components,
//                     place_id: place_id
//                 });
//                 saveAddress(newState);
//                 return newState;
//             } else {
//                 console.log("Could not process addressReducer, GEOCODE, action:", action);
//             }
//         break;
//         default:
//             return prevState;
//     }
// };

// export default combineReducers({
//     data,
//     isLoading,
//     error
//   });

// export default data;
