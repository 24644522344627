import React, { useState } from "react";
import styled from 'styled-components';

import Stamp from "./stamp";
import { claimCup } from "../../api";
import {rem} from '../../helpers/mixins';


const Container = styled.div`
    padding: ${rem(8)};
    background-color: #ECE9F1;
    margin-bottom: ${rem(20)};
    color: #5e2958;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    cursor: pointer;
`;

const CafeName = styled.span`
    text-transform: uppercase;
    font-weight: bold;
`;

const StyledChevron = styled.img`
    width: ${rem(30)};
    height: ${rem(30)};
`;

const CoffeeReward = styled.img`
    width: ${rem(120)};
    height: ${rem(120)};
`;

const Wrapper = styled.div`
    margin-bottom: ${rem(20)};
    display: flex;
    flex-direction: column;
`;

export const StampCardsListItem = ({ cupLocation }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [success, setSuccess] = useState(false);
    const amountOfStampsToGo = cupLocation.campaign_number_coffees_required - cupLocation.quantity_of_coffees;

    const handleClick = e => {
        e.preventDefault();
        setIsOpen(prev => !prev);
    }

    const shouldHideMap = () => {
        claimCup({
            user_id:cupLocation.user_id,
            cafe_id:cupLocation.cafe_id
            // count: true,
        }).then(() => setSuccess(!success))
    }

    if (success) {
        return (
            <Container>
                <Header>
                    <CafeName className="type--text">You have successfully claimed your free drink! Enjoy the treat</CafeName>
                        <CoffeeReward src="/assets/images/coffee-2-go-cup.svg" alt=""/>
                </Header>
            </Container>
        )
    }

    return (
        <Container>
            <Header onClick={e => handleClick(e)}>
                <CafeName className="type--text">{cupLocation.cafe_name}</CafeName>
                {
                    isOpen ? <StyledChevron src="/assets/images/chevron-up.svg" alt=""/> :
                        <StyledChevron src="/assets/images/chevron-down.svg" alt=""/>
                }
            </Header>
            {
                isOpen && (
                    <>
                        {
                            <Wrapper>
                                {cupLocation.quantity_of_coffees >= cupLocation.campaign_number_coffees_required ?
                                        <div className="flex flex-row margin-vertical">
                                            <span className="type--text" style={{flex: 1}}>
                                                Well done with your green efforts!
                                            </span>
                                            <button className="tertiary-button" onClick={shouldHideMap}>
                                                Claim free drink
                                            </button>
                                        </div> :
                                        <span className="type--text margin-vertical">
                                            Buy {amountOfStampsToGo} drinks to-go to redeem your free drink!
                                        </span>
                                }
                                <Stamp n={cupLocation.campaign_number_coffees_required}
                                              t={cupLocation.quantity_of_coffees}/>
                            </Wrapper>
                        }
                        {
                            cupLocation.campaign_number_coffees_required < cupLocation.quantity_of_coffees &&
                            (
                                <Wrapper>
                                    <div className="flex flex-row margin-vertical">
                                         <span className="type--text margin-vertical">
                                                Buy {cupLocation.campaign_number_coffees_required + amountOfStampsToGo} drinks to-go to redeem your free drink!
                                         </span>
                                    </div>
                                    <Stamp n={cupLocation.campaign_number_coffees_required}
                                           t={Math.abs(amountOfStampsToGo)} />
                                </Wrapper>
                            )
                        }
                    </>
                )
            }
        </Container>
    )
}
