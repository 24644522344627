import React, { useContext } from "react";

export const initialState = {
    personalInfo: { email: "", name: "" },
    time: 0,
    startTime: 0,
    endTime: 0,
    scores: [],
    status: "stopped", // "running" | "charging"
};

export const ActionType = {
    SAVE_PERSONAL_INFO: "SAVE_PERSONAL_INFO",
    SAVE_TIME: "SAVE_TIME",
    SET_LEADERBOARD_GAME: "SET_LEADERBOARD_GAME",
    SET_STATUS: "SET_STATUS",
    RESET_GAME: "RESET_GAME",
}


export function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case ActionType.SAVE_PERSONAL_INFO:
            return { ...state, personalInfo: action.payload };
        case ActionType.SAVE_TIME:
            return { ...state, time: action.payload };
        case ActionType.SET_LEADERBOARD_GAME:
            return { ...state, scores: action.payload };
        case ActionType.SET_STATUS:
            let time = { startTime: state.startTime, endTime: state.endTime };
            if (action.payload === "running") {
                time.startTime = Date.now();
            }
            if (action.payload === "charging") {
                time.endTime = Date.now();
            }
            return { ...state, ...time, status: action.payload };
        case ActionType.RESET_GAME:
            return initialState;
        default:
            return state;
    }
}

export const FixCoffeePathGameContext =
    React.createContext({});

export const useFixCoffeePathGameContext = () =>
    useContext(FixCoffeePathGameContext);
