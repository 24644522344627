import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

import { registerIonic } from '@ionic/react';
import * as serviceWorker from './serviceWorker';

import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/root';
import configureStore from './store/store';
import { merge } from 'lodash';

Sentry.init({
    dsn: 'https://65a83189f61a4d27bc3d803b85b56b1d@o1410783.ingest.sentry.io/4504876970409984',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

registerIonic();

document.addEventListener('DOMContentLoaded', () => {
    const root = document.getElementById('root');

    let store;
    let preloadedState = {};
    if (window.currentUser) {
        preloadedState = merge(preloadedState, { session: { currentUser: window.currentUser } });
        delete window.currentUser;
    }

    if (sessionStorage.address) {
        preloadedState = merge(preloadedState, { currentAddress: JSON.parse(sessionStorage.address) });
    }

    if (sessionStorage.restaurants) {
        preloadedState = merge(preloadedState, {entities: {restaurants: JSON.parse(sessionStorage.restaurants)}});
    }

    if (sessionStorage.restaurant) {
        preloadedState = merge(preloadedState, {entities: {restaurant: JSON.parse(sessionStorage.restaurant)}});
    }

    if (sessionStorage.order) {
        preloadedState = merge(preloadedState, {entities: {order: JSON.parse(sessionStorage.order)}});
    }

    if (sessionStorage.cup) {
        preloadedState = merge(preloadedState, {entities: {cup: JSON.parse(sessionStorage.cup)}});
    }

    if (sessionStorage.orderItems) {
        const orderItems = JSON.parse(sessionStorage.orderItems);
        for (let id in orderItems) {
            orderItems[id].options = new Map(orderItems[id].options);
        }
        preloadedState = merge(preloadedState, {entities: {orderItems}});
    }

    store = preloadedState ? configureStore(preloadedState) : configureStore();

    ReactDOM.render(<Root store={store}/>, root);
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
