import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as RightArrow } from '../../svg/right-arrow.svg';

const OrderCoffeeLink = (props) => {
    const colors = {
        white: "#fff",
        violet: "#5e2958",
        green: "#8DD4A1",
    };

    return (
        <Link to={props.url} style={{ color: colors[props.color] }} className="link--order-coffee strong">
            See menu and plan route
            <span className="link--order-coffee-icon">
                <RightArrow style={{ fill: colors[props.color] }} />
            </span>
        </Link>
    )
};

OrderCoffeeLink.propTypes = {
    url: string,
    color: string
};

OrderCoffeeLink.defaultProps = {
    color: 'white'
}

export default OrderCoffeeLink;
