// 1. Sardine - 110g
// 2. Crab - 228g
// 3. Octopus - 2kg
// 4. Lobster - 5 kg
// 5. Adriatic tuna (albacore) - 20kg - 50kg
// 6. Dolphin - 70kg
// 7. Whale - 3000 kg

const fishWeight = {
  mussel: 0.018,
  shrimp: 0.036,
  sardine: 0.11,
  crab: 0.228,
  octopus: 2,
  lobster: 5,
  tuna: 20,
  dolphin: 70,
  whale: 3000,
}

export function calcFishToDisplay(savedCupsInGrams) {
  const values = Object.values(fishWeight);

  for(let i = 0; i < values.length; i++) {
    const current = values[i];
    const previous = values[i - 1] || 0;
    const next = values[i + 1];

    if (savedCupsInGrams > previous && savedCupsInGrams < next) {
      const fishName = Object.keys(fishWeight)[Object.values(fishWeight).indexOf(current)];
      const fishCount = Math.floor(savedCupsInGrams / current);
      return { fishCount, fishName }
    }
  }
}

const fishAsset = {
  mussel: '/assets/images/mussel.svg',
  shrimp: '/assets/images/shrimp.svg',
  sardine: '/assets/images/sardine.svg',
  crab: '/assets/images/crab.svg',
  octopus: '/assets/images/octopus.svg',
  lobster: '/assets/images/lobster.svg',
  tuna: '/assets/images/tuna.svg',
  dolphin: '/assets/images/dolphin.svg',
  whale: '/assets/images/whale.svg',
}

export function iconPathToDisplay(savedCupsInGrams) {
  const key = calcFishToDisplay(savedCupsInGrams);
  if(key) {
    return fishAsset[key.fishName];
  }
}
