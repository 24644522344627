import React from 'react';
import {Link} from 'react-router-dom';
import {geolocated} from "react-geolocated";

import {getOpeningMessage} from "../../util/openingHours";
import {distanceInKmBetweenEarthCoordinates} from "../../util/distance";

import OrderCoffeeLink from '../../shared/components/order-coffee-link';
import { LocalOffer, MapRounded, WatchLaterOutlined } from "@material-ui/icons";


const RestaurantIndexItem = props => {
    const {_key, name, address, img_url, open_time, close_time, price } = props.restaurant;
    const openingMessage = getOpeningMessage({
        openingTime: open_time,
        closingTime: close_time
    })

    let distance = "? km"
    if (props.isGeolocationAvailable && props.location) {
        const {lat: latitude, lng: longitude} = props.location;
        distance = distanceInKmBetweenEarthCoordinates(latitude, longitude,
            props.restaurant.latitude, props.restaurant.longitude) + " km"
    }

    return (
        <Link to={`/restaurants/${_key}`}  style={{ textDecoration: "none" }}>
        <li className='restaurant-index-item'>
            <div className='restaurant-index-item--index-left-side'>
                <div className='restaurant-index-item--small-restaurant-image'
                     style={{backgroundImage: `url(${img_url ? img_url : '"/assets/images/cups-connecting.svg"'})`, backgroundRepeat: 'no-repeat'}}/>
                <div className='restaurant-index-item--index-restaurant-cuisine-container'>
                    <div className="flex align-items-center justify-content-between">
                        <h3 className="type--header-h2">{name}</h3>
                        {price &&
                          <div className="flex align-items-center margin-left-l">
                            <LocalOffer />
                            <span className="type--label"> {price} off with reusable cup </span>
                          </div>
                        }
                    </div>
                    <label className="type--small--strong margin-vertical-xs">{address}</label>
                    <span className="type--text flex align-items-center"><MapRounded />{`${distance} away`}</span>
                    <div className="type--text flex align-items-center">
                        <WatchLaterOutlined />
                        <div dangerouslySetInnerHTML={{__html: `${openingMessage}`}}/>
                    </div>
                    <div className="margin-top">
                        <OrderCoffeeLink url={`/restaurants/${_key}`} color="green" />
                    </div>
                </div>
            </div>
        </li>
        </Link>
    );
};

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(RestaurantIndexItem);
