import { combineReducers } from 'redux';
import { FETCH_SUCCESS_RESTAURANTS, FETCH_START_RESTAURANTS, FETCH_FAILURE_RESTAURANTS } from '../actions/restaurant_actions';
import { saveRestaurants } from '../session_storage/session_storage';

const initialState = {
  data: {},
  isLoading: false,
  error: {}
};

const data = (state = initialState.data, action) => {
  Object.freeze(state);
  switch(action.type) {
    case FETCH_SUCCESS_RESTAURANTS:
      saveRestaurants(action.restaurants);
      return action.restaurants;
    case FETCH_START_RESTAURANTS:
    case FETCH_FAILURE_RESTAURANTS:
      return initialState.data;
    default:
      return state;
  }
};

const isLoading = (state = initialState.isLoading, action) => {
  switch(action.type) {
    case FETCH_START_RESTAURANTS:
      return true;
    case FETCH_SUCCESS_RESTAURANTS:
    case FETCH_FAILURE_RESTAURANTS:
      return initialState.isLoading;
    default:
      return state;
  }
};

const error = (oldState = initialState.error, action) => {
  switch(action.type) {
    case FETCH_FAILURE_RESTAURANTS:
      return action.payload;
    case FETCH_START_RESTAURANTS:
    case FETCH_SUCCESS_RESTAURANTS:
      return initialState.error
    default:
      return oldState;
  }
};


export default combineReducers({
  data,
  isLoading,
  error
});
