import * as AddressSearchUtil from '../util/address_search_util';

export const GEOCODE = 'GEOCODE';
export const RECEIVE_ADDRESS_ERRORS = 'RECEIVE_ADDRESS_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const FETCH_SUCCESS_ADDRESS = 'FETCH_SUCCESS_ADDRESS';
export const FETCH_START_ADDRESS = 'FETCH_START_ADDRESS';
export const FETCH_FAILURE_ADDRESS = 'FETCH_FAILURE_ADDRESS';

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';

export const updateSearchAddress = data => ({
  type: UPDATE_ADDRESS,
  payload: data
});

export const receiveGeocode = geocodeResponse => ({
  type: FETCH_SUCCESS_ADDRESS,
  geocodeResponse
});

export const receiveErrors = (err) => ({
  type: RECEIVE_ADDRESS_ERRORS,
  error: `Invalid address. Please re-enter your location. Error:${err}`
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS
});

export const fetchGeocode = place_id => async dispatch => {
  try {
    dispatch({
      type: FETCH_START_ADDRESS
    });

    const geocodeResponse = await AddressSearchUtil.fetchGeocode(place_id);

    if (geocodeResponse && geocodeResponse.results && geocodeResponse.results[0]) {
      return dispatch(receiveGeocode(geocodeResponse));
    }
    
    throw new Error(geocodeResponse);
  } catch(error) {
    console.log(`fetchGeocode error: ${error}`);
    dispatch({
      type: FETCH_FAILURE_ADDRESS,
      payload: error
    });
    return dispatch(receiveErrors())
  }
};

export const fetchGeocodeByLatLng = (lat,lng) => async dispatch => {
  try {
    dispatch({
      type: FETCH_START_ADDRESS
    });

    const geocodeResponse = await AddressSearchUtil.fetchGeocodeByLatLng(lat,lng);
    
    if (geocodeResponse && geocodeResponse.results && geocodeResponse.results[0]) {
      return dispatch(receiveGeocode(geocodeResponse));
    }
    
    throw new Error(geocodeResponse);
  } catch(error) {
    console.log(`fetchGeocodeByLatLng error: ${error}`);
    dispatch({
      type: FETCH_FAILURE_ADDRESS,
      payload: error
    });
    return dispatch(receiveErrors())
  }
};


