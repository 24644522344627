import {fetchRestaurantsLatLong, fetchOrdersAPI} from "../api";

export const ORDERS = 'ORDERS';
export const FILTER_RATING = 'FILTER_RATING';
export const FILTER_PRICE = 'FILTER_PRICE';
export const FILTER_DELIVERY_FEE = 'FILTER_DELIVERY_FEE';
export const FILTER_ALL = 'FILTER_ALL';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const RECEIVE_FILTER_ERRORS = 'RECEIVE_FILTER_ERRORS';

export const FETCH_START_RESTAURANTS = 'FETCH_START_RESTAURANTS';
export const FETCH_SUCCESS_RESTAURANTS = 'FETCH_SUCCESS_RESTAURANTS';
export const FETCH_FAILURE_RESTAURANTS = 'FETCH_FAILURE_RESTAURANTS';

export const FETCH_START_ORDERS = 'FETCH_START_ORDERS';
export const FETCH_SUCCESS_ORDERS = 'FETCH_SUCCESS_ORDERS';
export const FETCH_FAILURE_ORDERS = 'FETCH_FAILURE_ORDERS';

export const receiveRestaurants = restaurants => {
  return ({
    type: FETCH_SUCCESS_RESTAURANTS,
    restaurants
  });
};

export const receiveOrders = orders => {
  return ({
    type: FETCH_SUCCESS_ORDERS,
    orders: orders || [],
  });
};


export const filterRating = (rating, restaurants) => {
  return ({
    type: FILTER_RATING,
    rating,
    restaurants
  });
};

export const filterPrice = (price, restaurants) => {
  return ({
    type: FILTER_PRICE,
    price,
    restaurants
  });
};

export const filterDeliveryFee = (deliveryFee, restaurants) => {
  return ({
    type: FILTER_DELIVERY_FEE,
    deliveryFee,
    restaurants
  });
};

export const filterAll = (rating, price, deliveryFee, restaurants) => {
  return ({
    type: FILTER_ALL,
    rating,
    price,
    deliveryFee,
    restaurants
  });
}

export const clearFilters = () => {
  return ({
    type: CLEAR_FILTERS
  });
};

export const receiveFilterErrors = (filterCategory) => {
  return({
    type: RECEIVE_FILTER_ERRORS,
    filterCategory,
    error: "No restaurants match that filter!"
  });
};

export const fetchRestaurants = location => dispatch => {
  try {
    dispatch({ type: FETCH_START_RESTAURANTS });
    return fetchRestaurantsLatLong(location).then((restaurants) => dispatch(receiveRestaurants(restaurants)))
  } catch (error) {
    return dispatch({ type: FETCH_FAILURE_RESTAURANTS, payload: error })
  }
};

export const fetchOrders  = location => dispatch => {
  try {
    dispatch({ type: FETCH_START_ORDERS });
    return fetchOrdersAPI(location).then((orders) => dispatch(receiveOrders(orders)));
  } catch (error) {
    return dispatch({ type: FETCH_FAILURE_ORDERS, payload: error })
  }
};
