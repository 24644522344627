
export async function addGameTimeToLeaderboard(data) {
    try {
        const response = await fetch(`${window.GO_API_ENDPOINT}/leaderboardgame`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error("Failed to fetch data");
        }
        return await response.json();
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(`HTTP error! Error: ${error.message}`);
        } else {
            throw new Error("An unknown error occurred during generateBoard");
        }
    }
}
