import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import styled from 'styled-components';
import {useQueryClient} from 'react-query';

import LoginStore from '../../store/LoginStore';

import {listAllCups, createCup, createCupLocation, fetchAllRewards} from "../../api";
import QrCodeReader from '../qr_code';
import * as OrderUtil from "../../util/order_util";
import CafesSuggestion from "../cafes_suggestion/cafes_suggestion";

const ScanButton = ({ text, handleAlert, handleRewards, simpleView }) => {
  const [isQrCodeOpen, setIsQrCodeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const isUserDashboard = useRouteMatch("/");
  const loggedIn = LoginStore.isLoggedIn();
  const userId = loggedIn && LoginStore.user._key
  const queryClient = useQueryClient();
  const [isCafesSuggestion, setIsCafesSuggestion] = useState(false);
  const [restaurantID, setRestaurantID] = useState("");
  const location = useLocation();
  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  // Get the value of the 'msg' parameter
  const msg = queryParams.get('msg');

  const handleQrCodeOpenState = () => {
    if (!loggedIn) {
      history.push(`/login`);
    } else {
      setIsQrCodeOpen(true);
    }
  }
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      setIsCafesSuggestion(true);
    }
  };

  useEffect(() => {
    if(msg === "success"){
      handleAlert && handleAlert('success')
      setIsCafesSuggestion(false);
      // Remove the query parameter from the URL
      history.replace(location.pathname);
      return
    } else if(msg === "error") {
      handleAlert && handleAlert('error')
      setIsCafesSuggestion(false);
      // Remove the query parameter from the URL
      history.replace(location.pathname);
      return
    }

    if(simpleView){
      setIsCafesSuggestion(false);
      return
    } else {
      // Show the modal immediately when the component mounts
      setIsCafesSuggestion(true);
    }
    // const intervalId = setInterval(() => {
    //   setIsCafesSuggestion(true);
    // }, 60000);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // clearInterval(intervalId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleCafesSuggestionStateClose = () => {
    setIsCafesSuggestion((current) => !current);
  }

  const handleQrCodeScan = async (restaurant) => {
    console.log("restaurant", restaurant)
    try {
      setIsLoading(true);

      let mycups = await listAllCups({
        user_id: LoginStore.user._key,
        status: 'MY_CUP',
        _sort: 'updated_at',
        _order: 'DESC',
      })

      if (loggedIn && isQrCodeOpen && mycups.length === 0) {
        const newCup = {
          brand: "smidge",
          cost: 12.99,
          description: "New cup",
          operator: "",
          status: 'MY_CUP',
          user_id: LoginStore.user._key,
          size: "medium"
        }
        await createCup(newCup);
        mycups = await listAllCups({
          user_id: LoginStore.user._key,
          status: 'MY_CUP',
          _sort: 'updated_at',
          _order: 'DESC',
        })
      }

      if (!restaurant || !restaurant._key) {
        console.error("ERROR, handleQrCodeScan, failed to scan cup ", restaurant);
        setIsLoading(false);
        setIsQrCodeOpen(false);
        handleAlert && handleAlert('error')
        return
      }
      setRestaurantID(restaurant._key);
      const cup_id = mycups[0]._key
      const restaurant_id = restaurant._key

      // add cup location entry for given restaurant id and user id
      // { user:id: current_user_id, cup_id: current_user.cup._key }
      // await addCupToUser({restaurant_id: restaurant._key, cup_id: })

      const newOrder = {
        status: "IN_TRANSIT",
        deliveryFee: "1",
        deliveryMinimum: "1",
        orderItemIds: ["15769719"],
        order_items_attributes: [
          {
            item_instructions: "",
            menu_item_id: "15769719",
            menu_item_name: "Espresso",
            price: "2.50",
            quantity: "1",
          }
        ],
        restaurant_id: restaurant_id,
        restaurantId: restaurant_id,
        subtotal: "2.5",
        tax: "0.22",
        tip: "null",
        total: "3.72",
      }

      const orderRequest = await OrderUtil.createOrderPending(newOrder);
      if (!orderRequest || !orderRequest._key){
        console.log("error createOrderPending, failed to create order");
        handleAlert && handleAlert('error');
        setIsLoading(false);
        setIsQrCodeOpen(false);
        return
      }

      const cupLocation = {
        user_id: LoginStore.user._key,
        cup_id: cup_id,
        order_id: orderRequest._id,
        status: "IN_TRANSIT",
        restaurant_id: restaurant_id,
      }

      const rewardsRequest = await fetchAllRewards({
        user_id: LoginStore.user._key,
        cafe_id: restaurant_id,
      })
      if (rewardsRequest && rewardsRequest.length>0){
        handleRewards && handleRewards(rewardsRequest)
      }

      const databack = await createCupLocation(cupLocation);
      await queryClient.invalidateQueries(['cup_location_by_user_id', { user_id: userId }]);
      await queryClient.invalidateQueries(['user_cup_count_by_user_id', {user_id: userId}]);
      await queryClient.invalidateQueries(['all_cup_location_count']);
      await queryClient.invalidateQueries(['cup_location_count_by_user_id', userId]);
      await queryClient.invalidateQueries(['cup_location_count_by_user_id', { user_id: userId }]);
      // await queryClient.invalidateQueries(['cup_rewards_by_user_id_cafe_id', { user_id: userId, cafe_id: restaurant_id }]);

      if (!databack || !databack._key){
        console.log("error createCupLocation, failed to cup location", cupLocation);
        handleAlert && handleAlert('error');
        setIsLoading(false);
        setIsQrCodeOpen(false);
        return
      }

      if (!isUserDashboard) {
        history.push(`/`);
      }

      handleAlert && handleAlert('success')
      setIsLoading(false);
      setIsQrCodeOpen(false);
    } catch (e) {
      console.error("ERROR, handleQrCodeScan, ", e);
      handleAlert && handleAlert('error');
      setIsLoading(false);
      setIsQrCodeOpen(false);
    }
  };

  return (
    <button>
      {simpleView && <button onClick={handleQrCodeOpenState}
                             className='type--small--strong user-cups-link'>{text}</button>}
      {!simpleView && <div role="button" onClick={handleQrCodeOpenState} className="scan-button-float" >Scan QR code</div>}
      {
          isQrCodeOpen ?
            <QrCodeReader
              open={isQrCodeOpen}
              handleClose={() => setIsQrCodeOpen(false)}
              handleScan={async(r) => {
                await handleQrCodeScan(r)
              }
            }
              isLoading={isLoading}
            />
            : null
      }
      {/*{*/}
      {/*  isCafesSuggestion ?*/}
      {/*      <CafesSuggestion*/}
      {/*          open={isCafesSuggestion}*/}
      {/*          handleClose={handleCafesSuggestionStateClose}*/}
      {/*          handleAlert={handleAlert}*/}
      {/*      />*/}
      {/*      : null*/}
      {/*}*/}
    </button>
  )
}

export default ScanButton;
