import React from 'react';
import {Link, withRouter} from 'react-router-dom';

const ErrorPage = props => {
  return (
    <div id='error-page' className='no-filter-results justify-content-center margin-xl'>
      <div className='flex justify-content-center inner-no-filter-results'>
        <img src={window.staticImages.no_results}/>
        <div>
          <h1 className="type--header-h1 margin-vertical-l">Uh Oh!</h1>
          <h3 className="type--header-h3 margin-bottom">It looks like the page you're looking for does not exist. <br/> Is there any chance
            you were looking for one of these?</h3>
          <div className="flex flex-column">
            <Link to='/'>Visit our homepage&#10095;</Link>
            <Link to='/restaurants'>Find a place to get yourself a cup of coffee&#10095;</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ErrorPage);
