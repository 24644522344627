import React from "react";

const AngleIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="8 7.5 22 24">
            <g transform="rotate(90 19.5 19.5)">
                <path fill="none" stroke={props.color} strokeWidth="1" d="M8 20h12V8" />
            </g>
        </svg>
    );
};

export default AngleIcon;
