import React, { useEffect, useRef, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { fetchGeocodeByAddress, fetchGeocodeByLatLng } from '../../../../util/address_search_util';

const errorTypes = {
    EMPTY_ADDRESS: 'EMPTY_ADDRESS',
};

export const ErrorMessage = ({ value, isHomepage }) => {
    return (
        value ?
            (
                <p className="search-form--error-message" style={{
                    textAlign: isHomepage ? 'center' : 'left'
                }}>
                    { value === errorTypes.EMPTY_ADDRESS ? 
                        'Please provide a valid location' 
                        : 'An error occurred and it was not possible to get your coordinates'
                    }
                </p>
            ) 
        : null
    )
}

export function SearchForm({ isLoadingRestaurants, onSubmit, onGeoLocationAvailable }) {
    const location = useLocation();
    const [address, setAddress] = useState({
        formattedAddress: '',
        location: {},
        addressComponents: {},
        place_id: ''
    });
    const [isLoadingCurrentLocation, setIsLocationCurrentLocation] = useState(false);
    const [error, setError] = useState(null);
    
    let searchBar = useRef(null);
    let autocomplete = null;

    const classNameContainer =  location.pathname === '/' ? 'address-search-form-homepage' : 'address-search-form-srp';
    const isHomepage = location.pathname === '/';
    const isRestaurantPage = matchPath(location.pathname, {
        path: "/restaurants",
    });

    useEffect(() => {
        // eslint-disable-next-line no-undef
        const defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(52.36489747176791, 4.890289306640625));
        const options = {
            offset: 3,
            types: ['address'],
            bounds: defaultBounds,
            strictBounds: false
        };

        // eslint-disable-next-line no-undef
        autocomplete = new google.maps.places.Autocomplete(searchBar, options);
        autocomplete.addListener('place_changed', handlePlaceChanged);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (Object.keys(address.location).length) {
            onSubmit({
                location: address.location,
                addressComponents: address.addressComponents,
                formattedAddress: address.formattedAddress
            });
        } else {
            try {
                const result = await fetchGeocodeByAddress(address.formattedAddress);
                
                onSubmit({
                    location: result.results[0].geometry.location,
                    addressComponents: result.results[0].address_components,
                    formattedAddress: address.formattedAddress
                });
            } catch (error) {
                setError(error);
            }
        }
    }

    useEffect(() => {
        if (location && location.state && location.state.formattedAddress && location.state.addressComponents && location.state.location) {
            setAddress({
                formattedAddress: location.state.formattedAddress,
                location: location.state.location,
                addressComponents: location.state.addressComponents,
            })
        }
    }, [location])

    const handleChange = e => {
        setAddress({
            location: {},
            addressComponents: {},
            place_id: '',
            formattedAddress: e.target.value,
        });
    }

    useEffect(() => {
        if(!!error && !!address.formattedAddress) {
            setError(null);
        }
    }, [error, address])

    const handlePlaceChanged = () => {
        try {
            let address = autocomplete.getPlace();
            const {name, formatted_address, place_id, address_components} = address;
            const {location} = address.geometry;

            setAddress({
                addressComponents: address_components,
                formattedAddress: formatted_address ? formatted_address : name,
                location: location,
                place_id: place_id,
            });
        } catch(error) {
            setError(error)
        }
    }

    const successCallback = async (position) => {
        try {
            const result = await fetchGeocodeByLatLng(position.coords.latitude,position.coords.longitude);
            
            setAddress({
                addressComponents: result.results[0].address_components,
                formattedAddress: result.results[0].formatted_address,
                location: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                place_id: result.results[0].place_id,
            });
            onGeoLocationAvailable && onGeoLocationAvailable(true);
            setIsLocationCurrentLocation(false);
            
            if (!!isRestaurantPage) {
                onSubmit({
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    addressComponents: result.results[0].address_components
                });
            }
        } catch (error) {
            setIsLocationCurrentLocation(false);
            setError(error);
        }
    };
      
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                if (location && !location.state) {
                    setIsLocationCurrentLocation(true);
                    successCallback(position)
                }
            },
            () => onGeoLocationAvailable && onGeoLocationAvailable(false),
            {
                enableHighAccuracy: true,
            }
        );
    }, []);

    return (
        <>
            <form className={classNameContainer}
                onSubmit={handleSubmit}>
                <div className={`${classNameContainer}--wrapper`}>
                    <img src="/assets/images/pin.svg"
                        className={`${classNameContainer}--pin`}
                        alt='map-pin-icon'
                    />
                    <input 
                        ref={(input) => {
                            searchBar = input;
                        }}
                        className={`${classNameContainer}--field`}
                        name='address'
                        type='text'
                        placeholder='What is your current location?'
                        value={address.formattedAddress}
                        onChange={handleChange}
                        disabled={isLoadingCurrentLocation}
                    />
                    <input type="text" className="search-form--hidden-input" />
                </div>
                <button
                    type="submit"
                    className={`primary-button primary-button--link ${isLoadingRestaurants || !!error || isLoadingCurrentLocation ? 'primary-button--disabled' : ''}`}
                >   
                    Find a café
                </button>
            </form>
            <ErrorMessage value={error} isHomepage={isHomepage} />
        </>
    )
}