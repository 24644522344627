import React from "react";
import {useQuery} from "react-query";
import { useTable } from 'react-table';

import {fetchLeaderboard} from "../../api/leaderboard.api";
import {truncateNameAndSurname} from "../../util/truncate_util";


const LeaderboardCups = () => {
    const { data, status } = useQuery('leaderboard', fetchLeaderboard);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Reusified cups',
                accessor: row => row.num_orders ? row.num_orders : 'N/A',
            },
            {
                Header: 'Reusifier',
                accessor: row => row.user_info ? truncateNameAndSurname(row.user_info.name) : 'N/A',
            },
        ],
        []
    );


    const filteredData = React.useMemo(() => {
        if (!data) {
            return [];
        }

        const namesSet = new Set();

        return data.filter(row => {
            // Check if user_info exists and is not null
            if (!row.user_info || !row.user_info.name) {
                return false;
            }

            // Check if name is already in the set (i.e., it's a duplicate)
            if (namesSet.has(row.user_info.name)) {
                return false;
            }

            // Add name to the set and include row in the filtered data
            namesSet.add(row.user_info.name);
            return true;
        });
    }, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: filteredData });

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'error') {
        return <div>Error: Unable to fetch data</div>;
    }

    return (
        <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '8px' }}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()} style={{  padding: '12px 0', textAlign: 'left' }}
                            className="type--text"
                        >{column.render('Header')}</th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.slice(0, 10).map((row, i) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()} style={{ borderBottom: '1px solid #f7f1e6' }}>
                        {row.cells.map(cell => (
                            <td
                                {...cell.getCellProps()}
                                style={{ textAlign: 'left', padding: '12px 0' }}
                                className="type--text"
                            >
                                {cell.render('Cell')}
                            </td>
                        ))}
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
}

export default LeaderboardCups;