import React from 'react';
import ContentLoader from 'react-content-loader';

const RestaurantsLoader = props => (
    <ContentLoader
      width="100%"
      height={500}
      title="Loading restaurants..."
      backgroundColor="#FFFFFF"
      foregroundColor="#F8F5F7"
      {...props}
    >
        <rect x="0" y="9.93" rx="5" ry="5" width="230" height="200" />
        <rect x="250" y="9.67" rx="0" ry="0" width="230" height="12.12"></rect>
        <rect x="250" y="28.67" rx="0" ry="0" width="150" height="9"></rect>
        <rect x="250" y="47.67" rx="0" ry="0" width="150" height="9"></rect>
        
        <rect x="0" y="220" rx="5" ry="5" width="230" height="200" />
        <rect x="250" y="220" rx="0" ry="0" width="230" height="12.12"></rect>
        <rect x="250" y="239" rx="0" ry="0" width="150" height="9"></rect>
        <rect x="250" y="258" rx="0" ry="0" width="150" height="9"></rect>
        
        <rect x="0" y="440" rx="5" ry="5" width="230" height="200" />
        <rect x="250" y="440" rx="0" ry="0" width="230" height="12.12"></rect>
        <rect x="250" y="459" rx="0" ry="0" width="150" height="9"></rect>
        <rect x="250" y="478" rx="0" ry="0" width="150" height="9"></rect>
    </ContentLoader>
);

const MapLoader = props => (
    <ContentLoader
      width="100%"
      height={500}
      title="Loading map..."
      backgroundColor="#ffffff"
      {...props}
    >
        <rect x="0" y="0" rx="2" ry="2" width="100%" height="500" />
    </ContentLoader>
);

export {
  RestaurantsLoader,
  MapLoader
};