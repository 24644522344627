import React, { useState, useEffect } from 'react';

const RedirectPopup = () => {
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCountdown(prevCount => prevCount - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (countdown <= 0) {
            window.location.href = "https://reusify.io";
        }
    }, [countdown]);

    const overlayStyles = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999
    };

    const contentStyles = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        width: '80%',
        maxWidth: '400px',
        textAlign: 'center'
    };

    return (
        <div style={overlayStyles}>
            <div style={contentStyles}>
                <p> Coffee2Go.io is now Reusify.io </p>
                <p>You will be redirected to Reusify.io in <span>{countdown}</span> seconds...</p>
            </div>
        </div>
    );
};

export default RedirectPopup;
