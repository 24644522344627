import React from 'react';
import { slide as Menu } from 'react-burger-menu';

import LogUser from './log_user';
import NavigationItems from './navigation_items';

import LoginStore from '../../store/LoginStore';

const NavigationMobile = ({ hasBackground }) => {
    const loggedIn = LoginStore.isLoggedIn();

    const handleLogout = e => {
        e.preventDefault();
        LoginStore.logout()
        if (document.location.hostname.indexOf('127.0.0.1') !== -1) {
            window.location.href = "http://127.0.0.1:3010/logout?referer=http://127.0.0.1:3000"
        } else {
            window.location.href = "https://api.reusify.io/logout?referer=https://www.reusify.io"
        }
    }

    return (
        <div className={`navigation-container ${hasBackground ? 'navigation-container--desktop' : ''}`}>
            <LogUser />
            <Menu
                customBurgerIcon={ <img src="/assets/images/menu_burguer.svg" alt="menu burguer icon" /> }
                customCrossIcon={ <img src="/assets/images/menu_close.svg" alt="menu close icon" /> }
                right
                width={'100%'} 
            >
                    <NavigationItems isMobile />
                    {
                        loggedIn ? (
                            <div className="log-user-container">
                                <button className="navigation-item-container--item" onClick={handleLogout}>Log out</button>
                            </div>
                        ) : null
                    }                    
            </Menu>
        </div>
    )
}

export default NavigationMobile;
