import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';

import App from './app';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
	},
});

const Root = ({ store }) => (
  <QueryClientProvider client={queryClient}>
    <Provider store={ store }>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </QueryClientProvider>
);

export default Root;
