import * as React from 'react';
import { Link } from 'react-router-dom';

import { navigationLinks, navigationTypes } from '../helpers/navigationLinks';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer--container">
        <div className="footer--logo"/>
        <ul>
          {
            navigationLinks(navigationTypes.FOOTER).map(({text, slug}, index) => (
              <li className="footer--link-item" key={`navigation-item-${index}`}>
                <a id={slug} className="primary-button primary-button--link" href={slug}>{text}</a>
              </li>
            ))
          }
        </ul>
        <ul className="footer--terms-and-rights-container">
          <li className="footer--terms-and-rights-item">
            <span>Copyright ©2024, Reusify B.V. | KVK: 90639952 | VAT ID: 865389196B01 | - All rights reserved.</span>
          </li>
         <li className="footer--terms-and-rights-item">
            <a id="policy-and-setting" href="https://docs.google.com/document/d/1BCN4XMwbzCVcxGJd1eMbWA6iJbh2z2wAcmgFkpO6Gx4/edit?usp=sharing">Privacy Policy</a>
          </li>
          <li className="footer--terms-and-rights-item">
            <a id="terms-of-use" href="https://docs.google.com/document/d/15XE778X3S-1g9fFer2P3b8TZ6G-RjtFRCiCs2T5D3PQ/edit?usp=sharing">Terms of Use</a>
          </li>
        </ul>
      </div>
    </footer>
  )
};

export default Footer;


