import React, {useRef} from 'react';
import styled from 'styled-components';
import {bool, func} from 'prop-types';
import {QrReader} from 'react-qr-reader';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import classNames from 'classnames';

const QrCodeReader = ({open, handleClose, handleScan, isLoading}) => {
    const scanInProgress = useRef(false);

    const previewStyle = {
        height: 300,
        width: 300
    };

    const onHandleScan = async (result, error, codeReader) => {
        try {
            // console.log("onHandleScan start")
            // if (codeReader) {
            //     console.log("onHandleScan, codeReader, ", codeReader)
            // }

            if (!!result && !scanInProgress.current) {
                scanInProgress.current = true;
                console.log('onHandleScan starting ...');
                const cup = JSON.parse(result);
                await handleScan(cup);
                scanInProgress.current = false;
            } else {
                console.log('onHandleScan WAIT ...');
                return;
            }

            if (!!error) {
                // console.info("onHandleScan, error, ", error);
            }

        } catch (e) {
            console.info("onHandleScan, catch error, ", error);
            scanInProgress.current = false;
        }
    };

    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classNames({'dialog-loading loader-dual-ring': isLoading})}
        >
            <DialogTitle className="qr-code--modal--title" id="alert-dialog-title">
                <p className="type--small--strong text-color--violet">Scan a cup</p>
            </DialogTitle>
            <DialogContent className={"qr-code--modal--content"}>
                <QrReader
                    constraints={{facingMode: "environment"}}
                    containerStyle={previewStyle}
                    onResult={async (r, e, c) => {
                        await onHandleScan(r, e, c);
                    }}
                />
            </DialogContent>
            <DialogActions className="qr-code--modal--actions">
                <button disabled={isLoading} className="tertiary-button tertiary-button--link"
                        onClick={handleClose}>Cancel
                </button>
            </DialogActions>
        </StyledDialog>
    );
}

const StyledDialog = styled(Dialog)`
    position: relative;

    &.dialog-loading .MuiPaper-root {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.2);
            width: 100%;
            height: 100%;
            z-index: 2;
        }
    }

    &.loader-dual-ring:after {
        z-index: 3;
    }
`

QrCodeReader.propTypes = {
    open: bool.isRequired,
    handleClose: func.isRequired,
    handleScan: func.isRequired,
};

export default QrCodeReader;
