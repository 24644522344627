import superagent from "superagent";

export const isAuth = async (retryCount = 0, retryDelay = 1000) => {
    const url = `${window.GO_API_ENDPOINT}/auth/isauth`;

    console.log("*&*&*&*&*&**&*&*& isAuth user");

    try {
        const response = await superagent
            .get(url)
            .withCredentials();
        return { status: response.status };
    } catch (err) {
        console.error("*&*&*&*&*&**&*&*& isAuth, isAuth failed to call, ", err.response.status);

        // If the error response status is 401, or we've exceeded our retry count, fail instantly
        if (err.response && err.response.status === 401 || retryCount >= 10) {
            return { status: 401 };
        }

        console.log(`*&*&*&*&*&**&*&*& isAuth, Retrying in ${retryDelay / 1000} seconds...`);
        await new Promise(resolve => setTimeout(resolve, retryDelay));
        return isAuth(retryCount + 1, retryDelay + 1000);
    }
};


export const logout = async () => {
    console.log("*&*&*&*&*&**&*&*& checkIsAuth user 401 logout")
    await localStorage.clear();
    await document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
}

export const checkIsAuth = async () => {
    console.log("*&*&*&*&*&**&*&*& checkIsAuth user")

    try {
        console.log("*&*&*&*&*&**&*&*& isAuth user")
        const result = await isAuth()
        if (result.status !== 200) {
            console.log("*&*&*&*&*&**&*&*& isAuth 401!!!!!!, result=", result)
            return false
        }
    } catch (err) {
        console.log("*&*&*&*&*&**&*&*& isAuth 401!!!!!!", err)
        return false
    }
    console.log("*&*&*&*&*&**&*&*& isAuth OK!")
    return true
}