import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {bool, func} from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import classNames from 'classnames';
import {Link, useLocation} from "react-router-dom";
import {ErrorMessage} from "../restaurant_index/components/SearchForm/SearchForm";
import {fetchGeocodeByLatLng} from "../../util/address_search_util";
import {fetchRestaurants} from "../../api";
import RestaurantIndexSuggestion from "./restaurant_index_suggestion";
import LoginStore from "../../store/LoginStore";
import {ReactComponent as RightArrow} from "../../shared/svg/right-arrow.svg";

const CafesSuggestion = ({open, handleClose, handleAlert}) => {

    const location = useLocation();
    const [isLoadingCurrentLocation, setIsLocationCurrentLocation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loggedIn = LoginStore.isLoggedIn();

    const [error, setError] = useState(null);
    const [restaurants, setRestaurants] = useState(null)
    const [address, setAddress] = useState({
        formattedAddress: '',
        location: {},
        addressComponents: {},
        place_id: ''
    });

    useEffect(() => {
        if (location && location.state && location.state.formattedAddress && location.state.addressComponents && location.state.location) {
            setAddress({
                formattedAddress: location.state.formattedAddress,
                location: location.state.location,
                addressComponents: location.state.addressComponents,
            })
        }
    }, [location])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                    setIsLocationCurrentLocation(true);
                    successCallback(position)
            },
            (e) => console.log("ERROR, getCurrentPosition, ", e),
            {
                enableHighAccuracy: true,
            }
        );
    }, []);

    const successCallback = async (position) => {
        try {
            setIsLoading(true);

            const restaurants = await fetchRestaurants({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                // active: false,
                _end: 50
            }, false)

            const closestPlaces = findClosestPlaces({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }, restaurants, 1200, 2)
            setRestaurants(closestPlaces)


            setAddress({
                location: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setIsLocationCurrentLocation(false);
            setError(error);
        }
    };

    const haversineDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371e3; // Earth's radius in meters
        const lat1Rad = lat1 * (Math.PI / 180);
        const lat2Rad = lat2 * (Math.PI / 180);
        const deltaLat = (lat2 - lat1) * (Math.PI / 180);
        const deltaLon = (lon2 - lon1) * (Math.PI / 180);

        const a =
            Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    };

    const findClosestPlaces = (location, places, maxDistance, limit = 2) => {
        const {lat, lng} = location;
        // console.log("findClosestPlaces, places, ", places)

        const filteredPlacesWithDistance = places
            .map((place) => ({
                ...place,
                distance: haversineDistance(lat, lng, place.latitude, place.longitude),
            }))
        // console.log("findClosestPlaces, filteredPlacesWithDistance, ", filteredPlacesWithDistance)

        const filteredPlacesWithDistanceFiltered =
            filteredPlacesWithDistance.filter((place) => {
                console.log("filteredPlacesWithDistance.filter, ", place.distance <= maxDistance)
                return place.distance <= maxDistance
            })
                .sort((a, b) => a.distance - b.distance)
                .slice(0, limit);
        // console.log("findClosestPlaces, filteredPlacesWithDistanceFiltered, ", filteredPlacesWithDistanceFiltered)

        return filteredPlacesWithDistanceFiltered;
    };

    console.log(`cafes_suggestion.jsx, loggedIn=${loggedIn}, isLoadingCurrentLocation=${isLoadingCurrentLocation}, restaurants=${restaurants}`)
    return (
        (loggedIn && restaurants && restaurants.length > 0) ?
        <StyledDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classNames({'dialog-loading loader-dual-ring': isLoading})}
        >
            <DialogTitle className="qr-code--modal--title" id="alert-dialog-title">
                <p className="type--small--strong text-color--violet">
                    Select the name of the café where you're currently caffeinating in style.</p>
            </DialogTitle>
            <DialogContent className={"qr-code--modal--content"}>
                {restaurants.map(restaurant => (
                    <RestaurantIndexSuggestion key={restaurant._key}
                                               restaurant={restaurant}
                                               isGeolocationAvailable={true}
                                               location={address.location}
                                               close={handleClose}
                                               handleAlert={handleAlert}
                    />
                ))}
                <ErrorMessage value={error} isHomepage={true}/>
                <a href="https://forms.gle/PKFLBNBne29hRJdU7" style={{ color: '#5e2958', textDecoration: 'underline' }} className="link--order-coffee strong">
                    Don't see the café? Let us know and we'll make sure to add it to our list.
                    <span className="link--order-coffee-icon">
                        <RightArrow style={{ fill: '#5e2958', width: '24px' }} />
                    </span>
                </a>
            </DialogContent>
            <DialogActions className="qr-code--modal--actions">
                <button disabled={isLoading} className="tertiary-button tertiary-button--link"
                        onClick={handleClose}>Cancel
                </button>
            </DialogActions>
        </StyledDialog> : null
    );
}

const StyledDialog = styled(Dialog)`
    position: relative;

    &.dialog-loading .MuiPaper-root {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.6);
            width: 100%;
            height: 100%;
            z-index: 2;
        }
    }

    &.loader-dual-ring:after {
        z-index: 3;
    }
`

CafesSuggestion.propTypes = {
    open: bool.isRequired,
    handleClose: func.isRequired,
};

export default CafesSuggestion;
