import React, { useState, useEffect, useCallback } from "react";
import {useMutation} from "react-query";

import {
    generateMatrixWithActiveCells,
    isCoffeePathValid
} from "../../util/fix_coffee_path_game";
import {ActionType, useFixCoffeePathGameContext} from "../../actions/gameboard-context";
import {generateBoard} from "../../api/generate-board";

import Confetti from "../confetti";
import Matrix from "./matrix";
import Timer from "./timer";
import Message from "./message";
import LeaderboardGame from "../leaderboardGame";

export const useGenerateBoardMutation = (dimension) => {
    const {
        mutate: generateBoardMutation,
        data,
        isLoading: isLoadingBoardGame,
        isError: errorMessageGameBoardNotAvailable,
    } = useMutation({
        mutationFn: () => generateBoard(dimension),
    });

    useEffect(() => {
        generateBoardMutation(dimension);
    }, [generateBoardMutation, dimension]);

    return {
        generateBoardMutation,
        isLoadingBoardGame,
        rows: data?.cells,
        errorMessageGameBoardNotAvailable,
    };
};

const GameBoard = () => {
    const { dispatch, state } = useFixCoffeePathGameContext();
    const [ showModal, setShowModal] = useState(false);

    const {
        isLoadingBoardGame,
        rows,
        errorMessageGameBoardNotAvailable,
    } = useGenerateBoardMutation(3);

    const [transformedRows, setTransformedRows] = useState([]);

    useEffect(() => {
        setTransformedRows(rows || []);
    }, [rows]);

    useEffect(() => {
        const initialCoordinates = { x: 0, y: 0, direction: "U" };

        if (
            transformedRows?.length <= 0 ||
            !isCoffeePathValid(transformedRows, initialCoordinates)
        ) {
            return;
        }

        setShowModal(true);

        dispatch({
            type: ActionType.SET_STATUS,
            payload: "charging",
        });

    }, [dispatch, transformedRows]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowModal(false);
        return () => clearTimeout(timeout);
        }, 4000)}, [showModal]);

    useEffect(() => {
        dispatch({
            type: ActionType.SET_STATUS,
            payload: "running",
        });
    }, [dispatch]);

    const toggleCellActive = useCallback(
        (rowIndex, cellIndex) => {
            const cableAtPosition = transformedRows[rowIndex][cellIndex];

            let degreeToRotate = 0;
            if (cableAtPosition.cableType === "STRAIGHT") {
                if (cableAtPosition.rotationDegree === 0) {
                    degreeToRotate = 90;
                }
            } else {
                degreeToRotate = (cableAtPosition.rotationDegree + 90) % 360;
            }

            setTransformedRows((prevRows) => {
                const updatedCell = {
                    ...prevRows[rowIndex][cellIndex],
                    isActive: true,
                    rotationDegree: degreeToRotate,
                };
                const updatedRows = [...prevRows];

                updatedRows[rowIndex][cellIndex] = updatedCell;

         //       generateMatrixWithActiveCells(transformedRows, initialCoordinates)

                return updatedRows;
            });
        },
        [transformedRows],
    );

    return (
        <div className="flex flex-wrap justify-content-center items-center margin-bottom-xxl gameboard-container">
            {
                showModal && (
                    <>
                        <Confetti />
                        <div className="flex align-items-center flex-direction-column">
                            <h3 style={{ "fontSize": "50px"}}>🎉</h3>
                            <p className="type--text margin-top-xxl">Wohoo! Pipeline fixed. <br/>
                                We are adding you to the leaderboard...
                            </p>
                        </div>
                    </>
                )
            }
            {errorMessageGameBoardNotAvailable && (
                <Message
                    data-testid="errMessageGameBoardNotAvailable"
                    messageText="Oh no! Looks like the game is not available. Try again later."
                />
            )}
            {isLoadingBoardGame && <Message messageText="Loading Board..." />}
            {!isLoadingBoardGame && !errorMessageGameBoardNotAvailable && state.status === "running" && (
                <div data-testid="matrix">
                    <Message
                        messageText="Congrats! Because you reusified your cup you earned a spot in The Coffee Quest." />
                    <Message
                        messageText="Fix the pipeline and help the coffee to flow from machine to cup as fast as possible."
                    />
                    <div className="coffee-machine-header">
                        <img src="/assets/images/coffee-maker-50.png"
                             className="image-coffee-maker"
                             alt='coffee maker'
                        />
                        <Timer />
                    </div>
                    <Matrix rows={transformedRows} toggleCellActive={toggleCellActive} />
                    <img src="/assets/images/coffee-to-go-cup-50.png"
                         className="image-coffee-cup"
                         alt='coffee cup'
                    />
                </div>
            )}
            {state.status === "charging" && !showModal && (
                    <LeaderboardGame />
            )}
        </div>
    );
};

export default GameBoard;
