import * as OrderUtil from '../util/order_util';

export const ADD_ITEM = 'ADD_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_ALL_ITEMS = 'DELETE_ALL_ITEMS';
export const CREATE_ORDER_START = 'CREATE_ORDER_START';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';

export const addItem = (id, name, price, quantity, itemInstructions, restaurantId, restaurantName, deliveryMinimum, deliveryFee, item_option_sections, options) => {
  return({
    type: ADD_ITEM,
    id,
    name,
    price,
    quantity,
    itemInstructions,
    restaurantId,
    restaurantName,
    deliveryMinimum,
    deliveryFee,
    item_option_sections,
    options
  });
};

export const deleteItem = (id, price, quantity) => ({
  type: DELETE_ITEM,
  id,
  price,
  quantity
});

export const deleteAllItems = () => ({
  type: DELETE_ALL_ITEMS
});

export const createOrderSuccess = ({
  type: CREATE_ORDER_SUCCESS,
}) 

export const createOrder = payload => dispatch => {
  dispatch({ type: CREATE_ORDER_START })
  return (
    OrderUtil.createOrder(payload)
  );
};


export const createOrderPending = payload => dispatch => {
  return (
      OrderUtil.createOrderPending(payload)
  );
};

export const orderStatus = payload => dispatch => {
  return (
      OrderUtil.orderStatus(payload)
  );
};



