import React, { useState } from 'react';
import {GoogleMap, Marker} from '@react-google-maps/api';
import { isMobileOnly } from 'react-device-detect';

import CustomRestaurantMarker from './custom-restaurant-marker';

const Map = ({ markers, styleProps = {}, parentComponent, location}) => {
    const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState('');
    const mapLocation = location ? location : {
        lat: 52.3667,
        lng: 4.8945
    }

    const closeInfoWindow = () => setOpenInfoWindowMarkerId('');
    let getMarkers = [];

    if(parentComponent === 'restaurants') {
        getMarkers = markers.map((marker, index) => (
            <CustomRestaurantMarker
                key={`marker-${index}`}
                marker={marker}
                onHandleClick={() => setOpenInfoWindowMarkerId(`${index}`)}
                onHandleClose={closeInfoWindow}
                isInfoWindownOpen={openInfoWindowMarkerId === `${index}`} />
        ));
    } else {
        getMarkers = markers.map((marker, index) => (
            <Marker
                key={index}
                position={{
                    lat: markers[index].latitude,
                    lng: markers[index].longitude
                }}
                icon={"/assets/images/pin-map.svg"}
            />
        ));
    }

    if (markers && markers.length > 0) {
        return (
            <GoogleMap
                mapContainerClassName="google-map-container"
                mapContainerStyle={{
                    height: '700px',
                    width: '500px',
                    borderRadius: 1,
                    margin: isMobileOnly ? '0' : '0 0 0 16px',
                    flex: 1,
                    ...styleProps,
                }}
                zoom={13}
                center={mapLocation}
                options={{
                    draggable: true, // make map draggable
                    scaleControl: true, // allow scale controle
                    scrollwheel: true, // allow scroll wheel
                }}
            >
                {getMarkers}
            </GoogleMap>
        )
    }
    return null
}

export default Map;
