import React from 'react'
import {connect} from 'react-redux'
import {Redirect, Route, withRouter} from 'react-router-dom'
import LoginStore from "../store/LoginStore";

// Render wrapped component if not logged in, otherwise redirect
const Auth = ({component: Component, path, loggedIn, exact}) => {
    return (
        <Route
            path={path}
            exact={exact}
            render={props =>
                !loggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/"/>
                )}
        />
    )
}

// Render component if logged in, otherwise redirect
const Protected = ({component: Component, path, _, exact}) => {
    const loggedIn = LoginStore.isLoggedIn();
    if (!loggedIn) {
        console.log("Protected route, pre-conditions are not satisfied, will redirect to index path.",
            "loggedIn", loggedIn)
    }
    return (
        <Route
            path={path}
            exact={exact}
            render={props =>
                loggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login"/>
                )}
        />
    )
}

// Render component if address is present in, otherwise redirect
const AddressRequired = ({component: Component, path, address, exact}) => {
    if (!address) {
        console.log("AddressRequired route, pre-conditions are not satisfied, will redirect to index path.",
            "address", address,
            "path", path,
            "exact", exact
        )
    }
    return (
        <Route
            path={path}
            exact={exact}
            render={props =>
                address ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/"/>
                )}
        />
    )
};

const mapStateToProps = state => {
    return {
        loggedIn: Boolean(state.session.currentUser),
        address: String(state.currentAddress.formattedAddress),
        order: Boolean(Object.keys(state.entities.orderItems).length !== 0),
        deliveryMinimumReached: Boolean(state.entities.order.total >= state.entities.order.deliveryMinimum)
    }
};

// Need withRouter because Auth and Protected aren't actual routes
// withRouter passes route props to non-Route components
export const AuthRoute = withRouter(
    connect(mapStateToProps)(Auth)
);

export const ProtectedRoute = withRouter(
    connect(mapStateToProps)(Protected)
);

export const AddressRequiredRoute = withRouter(
    connect(mapStateToProps)(AddressRequired)
);
