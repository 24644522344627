import React from 'react';

import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

const Confetti = () => {
    return (
        <Fireworks autorun={{ speed: 3, duration: 2000 }} />
    );
}

export default Confetti;