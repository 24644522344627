import React from 'react';

export default class MenuItem extends React.Component {
    constructor(props) {
        super(props);
        // this.handleClick = this.handleClick.bind(this);
    }

    // handleClick() {
    //     this.props.toggleMenuItemModal();
    //     this.props.selectItem(this.props.menuItem);
    // }

    render() {
        const {name, price} = this.props.menuItem;

        return (
            <div className='menu-item-container'>
                <div className='item-name-and-price type--header-h4'>
                    <h4 className="type--small--strong">{name}</h4>
                    <h4 className="type--small--strong">€{price && price.toFixed(2)}</h4>
                </div>
            </div>
        );
    }
}
