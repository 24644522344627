import React from "react";

const Message = ({ messageText }) => (
    <p className="flex type--text margin-bottom">
        {" "}
        {messageText}
    </p>
);

export default Message;
