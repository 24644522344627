import React, { useMemo } from 'react';
import {connect} from 'react-redux';
import {geolocated} from "react-geolocated";
import { useQuery } from 'react-query';
import Media from 'react-media';
import { StickyContainer, Sticky } from 'react-sticky';
import { useLocation } from 'react-router-dom';

import { fetchRestaurants } from '../../api/restaurants.api';
import {clearErrors, receiveErrors} from "../../actions/address_actions";
import {deleteAllItems} from '../../actions/order_item_actions';
import {clearFilters} from '../../actions/restaurant_actions';
import {breakpoints} from "../../helpers/breakpoints";
import Map from "../map/map"
import Layout from "../layout";
import RestaurantIndexItem from './restaurant_index_item';

import { RestaurantsLoader, MapLoader } from './loaders';
import {Button} from "@material-ui/core";
import { useState } from 'react';
import { SearchForm } from './components/SearchForm/SearchForm';
import { useEffect } from 'react';

function RestaurantIndex(props) {
    const [isMap, setIsMap] = useState(false);
    const [geocode, setGeocode] = useState(null);
    const [isGeoLocationAvailable, setIsGeoLocationAvailable] = useState(false);
    const routeLocation = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const {data: restaurants = [], isLoading: isLoadingFetchRestaurants} = useQuery(
        ['restaurants_list', {
          geocode: geocode,
        }],
        () => fetchRestaurants({
            lat: geocode.location.lat,
            lng: geocode.location.lng,
            active: true
        }),
        {
            keepPreviousData: true,
            enabled: !!geocode,
            onSettled: () => setIsLoading(false),
        }
    );

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0
        });
    })
    useEffect(() => {
        if (routeLocation.state && Object.keys(routeLocation.state).length > 0) {
            const { location, addressComponents } = routeLocation.state;
            setGeocode({
                location,
                addressComponents,
            });
        }
    }, [routeLocation]);

    const filteredRestaurants = useMemo(() => {
        return restaurants.filter((restaurant) =>
            geocode.addressComponents.filter((address) => address.long_name === restaurant.city).length > 0
        );
    }, [restaurants])

    const shouldHideMap = () => {
        setIsMap(!isMap);
    }

    const onSearchSubmit = ({ location, addressComponents }) => {
        setGeocode({
            location,
            addressComponents
        });
        setIsLoading(true);
    }

    const getMarkers = useMemo(() => {
        return restaurants.map(restaurant => {
            if (restaurant && restaurant.latitude && restaurant.longitude) {
                return {
                    ...restaurant,
                    isGeolocationAvailable: isGeoLocationAvailable,
                    coords: props.coords,
                }
            }
            return null;
        })
    }, [restaurants]);

    return (
        <Layout>
            <div className='restaurant-index flex justify-content-center flex-column'>
                <SearchForm isLoadingRestaurants={isLoadingFetchRestaurants} onSubmit={onSearchSubmit} onGeoLocationAvailable={setIsGeoLocationAvailable}/>
                {
                    !isGeoLocationAvailable && (
                        <p className="search-form--out-of-bonds">
                            Please enter your location so we can find cafés near you. <br/>
                        </p>
                    )
                }
                <div className="flex justify-content-between align-items-end margin-vertical">
                    <p className="type--paragraph">
                        {`${filteredRestaurants.length} cafés`}
                    </p>
                </div>
                {filteredRestaurants.length >= 1 &&
                    <Media
                      query={{maxWidth: breakpoints.maxWidth.l}}
                      render={() => <Button variant="outlined"
                                            size="small"
                                            fullWidth={false}
                                            onClick={shouldHideMap}>View on map</Button>}
                    />
                }
                <div className="restaurant-index--container">
                    {
                        geocode && isLoadingFetchRestaurants || isLoading
                            ?
                                <div className="restaurant-index--wrapper flex margin-bottom-xl">
                                    <RestaurantsLoader />
                                    <Media
                                        query={{minWidth: breakpoints.minWidth.l}}
                                        render={() => <MapLoader />}
                                    />
                                </div>
                            : (
                            (isGeoLocationAvailable || geocode) && filteredRestaurants.length === 0 ?
                                    <div className='restaurant-index--no-filter-results justify-content-center margin-xxl'>
                                        <div className='restaurant-index--inner--no-filter-results flex justify-content-center'>
                                            <div className='restaurant-index--inner--image'>
                                                <img src={window.staticImages.no_results} alt="coffee cup" />
                                            </div>
                                            <div  className='restaurant-index--inner--text'>
                                                <h1 className="type--header-h2 margin-vertical-l">No cafés near you!</h1>
                                                <h3 className="type--paragraph margin-bottom">We didn't find any cafés in your
                                                    area matching your address. At the moment we are present in Amsterdam, NL and Porto, PT. </h3>
                                            </div>
                                        </div>
                                    </div> : (
                                        <div className="restaurant-index--wrapper flex margin-bottom-xl margin-right-l">
                                            <Media
                                                query={{maxWidth: breakpoints.maxWidth.l}}
                                                render={() =>
                                                    <React.Fragment>
                                                        {!isMap && <ul className='restaurant-index--list-wrapper'>
                                                            {filteredRestaurants.map(restaurant => (
                                                                 <RestaurantIndexItem key={restaurant._key}
                                                                    restaurant={restaurant}
                                                                    isGeolocationAvailable={isGeoLocationAvailable}
                                                                    coords={props.coords}
                                                                    location={geocode.location} />
                                                            ))}
                                                        </ul>}
                                                        {isMap && <Map markers={getMarkers} parentComponent="restaurants" location={geocode.location} />}
                                                    </React.Fragment>
                                                }
                                            />
                                            <Media
                                                query={{minWidth: breakpoints.minWidth.l}}
                                                render={() =>
                                                    <>
                                                        <ul className='restaurant-index--wrapper' style={{ width: '55%'}}>
                                                        {filteredRestaurants.map(restaurant => (
                                                                 <RestaurantIndexItem
                                                                    key={restaurant._key}
                                                                    restaurant={restaurant}
                                                                    isGeolocationAvailable={isGeoLocationAvailable}
                                                                    coords={props.coords}
                                                                    location={geocode.location} />
                                                            ))}
                                                        </ul>
                                                        <StickyContainer className={"sticky-map"} style={{ flex: 1 }}>
                                                            <Sticky topOffset={"-80"}>
                                                                {({style, isSticky}) => (
                                                                    <div style={{
                                                                        ...style,
                                                                        top: isSticky ? '80px' : 0,
                                                                    }}>
                                                                        <Map
                                                                            markers={getMarkers}
                                                                            parentComponent="restaurants"
                                                                            location={geocode && geocode.location}
                                                                            styleProps={{
                                                                                width: "calc(100% - 16px)",
                                                                                height: isSticky ? '620px' : '700px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Sticky>
                                                        </StickyContainer>
                                                    </>}
                                            />
                                        </div>
                                    )
                            )
                    }
                </div>
            </div>
        </Layout>
    );
}


const mapStateToProps = (state) => ({
    restaurants: Object.values(state.entities.restaurants.data),
    isLoadingRestaurants: state.entities.restaurants.isLoading,
    filteredRestaurants: Object.values(state.entities.filteredRestaurants),
    activeFilters: Object.keys(state.entities.filters).length !== 0,
    address: state.currentAddress.data,
    error: state.errors.address
});

const mapDispatchToProps = dispatch => ({
    deleteAllItems: () => dispatch(deleteAllItems()),
    clearFilters: () => dispatch(clearFilters()),
    receiveErrors: errors => dispatch(receiveErrors(errors)),
    clearErrors: () => dispatch(clearErrors()),
    fetchRestaurants: location => dispatch(fetchRestaurants(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(RestaurantIndex));
