import {createCup, listAllCups} from "../api";
import LoginStore from "../store/LoginStore";

export const CREATE_CUP_START = 'CREATE_CUP_START';
export const CREATE_CUP_SUCCESS = 'CREATE_CUP_SUCCESS';
export const CREATE_CUP_FAILURE = 'CREATE_CUP_FAILURE';

export const createNewCup = () => async (dispatch) => {
  try {
    dispatch({type: CREATE_CUP_START});

    const mycups = await listAllCups({
      user_id: LoginStore.user._key,
      status: 'MY_CUP',
      _sort: 'updated_at',
      _order: 'DESC',
    })

    if(!mycups || !mycups.length || !mycups[0]._key) {
      const newCup = {
        brand: "smidge",
        cost: 12.99,
        description: "New cup",
        operator: "",
        status: 'MY_CUP',
        user_id: LoginStore.user._key,
        size: "medium"
      }

      const data = await createCup(newCup);

      if (!data) {
        const errMsg = "error createNewCup, failed"
        console.log(errMsg);
      }
    }

    return dispatch({type: CREATE_CUP_SUCCESS});

  } catch (e) {
    console.log("createNewCup error, ", e)
    return dispatch({type: CREATE_CUP_FAILURE});
  }
}
