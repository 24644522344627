import React, {useEffect, useState} from 'react';
import {useQuery} from "react-query";
import styled from 'styled-components';
import {Link, useHistory, useParams} from 'react-router-dom';

import { StampCardsListItem } from "./stamp-cards-list-item";
import Loading from "../loading";
import LoginStore from "../../store/LoginStore";
import {checkIsAuth, fetchAllRewards} from "../../api";
import {rem} from '../../helpers/mixins';

const Container = styled.div`
    padding: ${rem(20)};
`;

const StyledH2 = styled.h2`
    margin-top: ${rem(60)};
    margin-bottom: ${rem(25)};
`

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
`;

const StyledLeftArrow = styled.span`
    width: ${rem(30)};
    height: ${rem(30)};
    margin-right: ${rem(10)};
    background: url('/assets/images/arrow-left.svg');
    background-repeat: no-repeat;
    background-size: cover;
`;

const StampCardsList = () => {
    const {id} = useParams();
    let history = useHistory();
    const loggedIn = LoginStore.isLoggedIn();
    const [isLogin, setLogin] = useState(loggedIn);

    useEffect(() => {
        (async () => {
            const isLoggedIn = await checkIsAuth();
            setLogin(isLoggedIn);
            if(!isLoggedIn) {
                LoginStore.logout();
            }
        })()
    }, [])

    if(!isLogin) {
        history.push({pathname: `/login`});
    }

    const {data: userStampCards = [], isLoading: isLoadingUseruserStampCards} = useQuery(
        ['user_cup_rewards_by_user_id', {
            user_id: id,
        }],
        () => fetchAllRewards({
            user_id: id,
        }),
        {
            keepPreviousData: true,
        }
    );

    return (
        <Container className='user-dashboard'>
            <Loading isLoading={isLoadingUseruserStampCards}></Loading>
            <StyledLink id='cups-go-back' className='type--small--strong user-cups-link' to={`/user/${LoginStore.user._key}/impact`}>
                <StyledLeftArrow />
                <span>Go back</span>
            </StyledLink>
            <StyledH2 className="type--header-h2 margin-top-l">My stamp cards</StyledH2>
            {
                userStampCards.length === 0 && <h3 className="type--paragraph">You have no stamp cards.</h3>
            }
            {userStampCards.length > 0 ? (
                <div>
                    {userStampCards.map((userStampCard, i) => (
                        userStampCard.cafe_id !== "demo" &&
                        <StampCardsListItem cupLocation={userStampCard} key={`digital-card-${userStampCard.key}-${i}`} />
                    ))}
                </div>
            ) : (
                <div/>
            )}
        </Container>
    )
}

export default StampCardsList;