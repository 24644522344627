import React from 'react';
import {geolocated} from "react-geolocated";
import styled from "styled-components";

import {getOpeningMessage} from '../../util/openingHours';
import {distanceInKmBetweenEarthCoordinates} from "../../util/distance";

import MenuItem from '../menu/menu_item';
import ErrorPage from '../error_page';
import Layout from "../layout";
import PlanRouteLink from '../plan_route_link';

import Loading from '../loading';
import {LocalOffer, MapRounded, WatchLaterOutlined} from "@material-ui/icons";

class RestaurantShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentItem: null
    };

    this.selectItem = this.selectItem.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const ID = this.props.match.params.id;

    this.props.fetchRestaurant(ID);
    this.props.fetchMenuItems(ID);

    this.setMap();
  }

  setMap() {
    const map = this.refs.map;
    if (!this.state.map) {
      const lat = this.props.restaurant.latitude;
      const lng = this.props.restaurant.longitude;
      /*eslint-disable no-undef*/
      this.map = new google.maps.Map(map, {
        center: { lat: parseFloat(lat), lng: parseFloat(lng)},
        zoom: 15,
        clickableIcons: false,
        draggable: false,
        disableDefaultUI: true
      });

      /*eslint-disable no-undef*/
      const marker = new google.maps.Marker({
        position: {lat, lng}
      });

      marker.setMap(this.map);
      this.setState({map: true});
    }
  }

  componentDidUpdate() {
    if (this.props.match.params.id !== this.props.restaurant._key) {
      this.props.fetchRestaurant(this.props.match.params.id);
      this.props.fetchMenuItems(this.props.match.params.id);
    }
    this.setMap();
  }

  selectItem(selectedItem) {
    this.setState({currentItem: selectedItem});
  }

  render() {
    if (this.props.restaurant) {
      const {name, address, img_url, open_time, close_time, latitude, longitude, cuisine, price} = this.props.restaurant;
      const {toggleMenuItemModal, isLoading} = this.props;

      const openingMessage = getOpeningMessage({
        openingTime: open_time,
        closingTime: close_time
      })

      const menuItems = this.props.menuItems.map((menuItem, index) => {
        return <MenuItem key={`${menuItem.id}-${index}`} menuItem={menuItem} selectItem={this.selectItem}
                         toggleMenuItemModal={toggleMenuItemModal}/>;
      });

      // const isOpen = false;
      const isOpen = openingMessage ? openingMessage.indexOf("Open Now") !== -1 : false;

      if (!isOpen && !this.state.cleared) {
        console.log("place is not open ? close deleteAllItems");
        this.setState({cleared: true});
        this.props.deleteAllItems();
      }

      let distance = "Calculating distance"
      if (this.props.isGeolocationAvailable && this.props.coords && this.props.coords.latitude) {
        const {latitude, longitude} = this.props.coords;
        distance = distanceInKmBetweenEarthCoordinates(latitude, longitude, this.props.restaurant.latitude, this.props.restaurant.longitude) + " km"
      }

      const restaurantImage = img_url ? img_url : "/assets/images/cups-connecting.svg";

      return (
        <Layout>
          <Loading isLoading={isLoading}/>
          <div className="restaurant-show-main">
            <div className="section--intro">
              <h2 className="type--header-h1">{name}</h2>
              {price &&
              <div className="flex align-items-center justify-content-center">
                <LocalOffer/>
                <span className="type--text"> {price} off with reusable cup </span>
              </div>
              }
              <span
                className="type--text flex align-items-center justify-content-center"><MapRounded/>{`${distance} away`}</span>
              <div className="type--text flex align-items-center justify-content-center">
                <WatchLaterOutlined/>
                <div dangerouslySetInnerHTML={{__html: `${openingMessage}`}}/>
              </div>
              <p className="type--small--strong text-align-center margin-top">{cuisine}</p>
              <div className="align-items-center">
                {
                  this.props.isGeolocationAvailable && this.props.coords
                    ?
                    <PlanRouteLink coordinates={{
                      latitude: latitude,
                      longitude: longitude,
                      name: name,
                      address: address
                    }}/>
                    : null
                }
              </div>
            </div>
            <div className="section--menu-items section--background">
              <div className="section--container">
                <h3 className="type--header-h2">Menu</h3>
                <div className="flex justify-content-between padding-bottom">
                  <div className="section--menu">
                    {
                      menuItems
                    }
                  </div>
                  <div className={`top-info-container ${!img_url ? 'top-info-container--no-image' : ''}`}
                       style={{backgroundImage: `url(${restaurantImage})`}}/>
                </div>
              </div>
            </div>
            <div className="section--map">
              <div className="section--map-container">
                <p className="address type--label">{address}</p>
                <div className='map' ref='map'>
                  Map
                </div>
              </div>
            </div>
          </div>
        </Layout>
      );
    } else {
      return (
        <ErrorPage/>
      );
    }
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(RestaurantShow);
