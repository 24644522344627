import React from 'react';
import {connect} from 'react-redux';

const Login = () => {
    let referer = "";
    if (document.location.hostname.indexOf("127.0.0.1") !== -1) {
        referer = "http://127.0.0.1:3000"
    } else if (document.location.hostname.indexOf("localhost") !== -1) {
        referer = "http://localhost:3000"
    } else {
        referer = window.HOST
    }

    const URL = `${window.GO_API_ENDPOINT}?referer=${referer}`

    window.location.href = URL;

    return (
        <div className={"loader-dual-ring"}></div>
    )
};

export default connect(null)(Login);
