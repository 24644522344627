
function degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
}

export function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    try {
        const earthRadiusKm = 6371;

        const latSin = Math.sin(degreesToRadians(lat2 - lat1) / 2);
        const LonSin = Math.sin(degreesToRadians(lon2 - lon1) / 2);

        const lat1Cos = Math.cos(degreesToRadians(lat1));
        const lat2Cos = Math.cos(degreesToRadians(lat2));

        const a = latSin * latSin +
            LonSin * LonSin * lat1Cos * lat2Cos;

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return (earthRadiusKm * c).toFixed(2);
    }catch(e){
        console.log("Error: distanceInKmBetweenEarthCoordinates", e);
        return 0
    }
}
