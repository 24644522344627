import qs from "querystring";

const superagent = require('superagent');

export const fetchLeaderboard = async (query) => {
    const url = `${window.GO_API_ENDPOINT}/v1/list/leaderboard?${query ? `${qs.encode(query)}` : ''}`
    console.error("fetchLeaderboard starting ", url)
    try {
        const response = await superagent
            .get(url)
            .withCredentials();
        return response.body;
    } catch (err) {
        console.error("fetchLeaderboard failed ", err)
        throw new Error('Network response was not ok');
    }
};
