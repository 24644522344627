import React from 'react';
import { Link } from 'react-router-dom';

import LogUser from './log_user';
import NavigationItems from './navigation_items';

const Navigation = () => {
    return (
        <div className="navigation-container navigation-container--desktop">
            <LogUser />
            <Link id="navigation-logo" to="/">
                <div className="navigation-container--logo" />
            </Link>
            <NavigationItems />
        </div>
    )
}

export default Navigation;
